import React, { useEffect, useRef } from 'react'
import { Box, TextField } from '@mui/material'
import { useState } from 'react'
import GenericSelect from '../../../common/components/GenericSelect'

export default function MessageField({ input }) {
  const [fieldValue, setFieldValue] = useState('')
  const { value, onChange } = input
  const textFieldRef = useRef(null)
  useEffect(() => {
    onChange(fieldValue)
  }, [fieldValue])

  useEffect(() => {
    setFieldValue(value)
  }, [])
  const handleVariableChange = e => {
    const variable = e.target.value
    const cursorPosition = textFieldRef.current.selectionStart
    const newValue = fieldValue.slice(0, cursorPosition) + variable + fieldValue.slice(cursorPosition)
    setFieldValue(newValue)
    setTimeout(() => {
      textFieldRef.current.selectionStart = cursorPosition + variable.length
      textFieldRef.current.selectionEnd = cursorPosition + variable.length
    }, 0)
  }
  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={1}>
      <GenericSelect value={''} options={variablesOptions} onChange={handleVariableChange} label={'Variables'} />
      <TextField
        inputRef={textFieldRef}
        onChange={e => {
          setFieldValue(e.target.value)
        }}
        placeholder={'Message'}
        value={value}
        multiline
        rows={6}
        maxRows={12}
        sx={{
          width: '100%',
          resize: 'none',
          '& .MuiOutlinedInput-root': {
            fontSize: 16,
            fontWeight: 400,
            fontFamily: 'Plus Jakarta Sans',
            borderRadius: '10px'
          }
        }}
      />
    </Box>
  )
}

const variablesOptions = [
  { name: 'First name', value: '[firstName]' },
  { name: 'Last name', value: '[lastName]' },
  { name: 'Email', value: '[email]' },
  { name: 'Company short name', value: '[companyShortName]' },
  { name: 'Company name', value: '[companyName]' },
  { name: 'Phishing link', value: '[phishingLink]' }
]
