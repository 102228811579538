import React, { useContext } from 'react'
import { PageNumberContext } from './PageNumberContext'
import ChooseTypeCard from './ChooseTypeCard'
import { Field, useFormState } from 'react-final-form'
import { Box } from '@mui/material'

import GenericSelect from '../../../common/components/GenericSelect'

import { phoneNumbersOptions } from './utils'
import MessageField from './MessageField'

export default function SmsWhatsappForm() {
  const { values } = useFormState()
  const { setPage } = useContext(PageNumberContext)

  const cardMapper = {
    AUTOMATED_EMAIL: {
      type: 'AUTOMATED_EMAIL',
      title: 'Email',
      description: 'Send automated emails',
      isAI: true,
      onChange: () => setPage(1)
    },
    MANUAL_EMAIL: {
      type: 'MANUAL_EMAIL',
      title: 'Personalized Emails',
      description: 'Craft and dispatch custom emails',
      isAI: true,
      onChange: () => setPage(1)
    },
    SMS: {
      type: 'SMS',
      title: 'SMS',
      description: 'Send text messages automatically',
      isAI: false,
      onChange: () => setPage(1)
    },
    WHATSAPP: {
      type: 'WHATSAPP',
      title: 'Whatsapp',
      description: 'Automate WhatsApp messages',
      isAI: false,
      onChange: () => setPage(1)
    },
    SLACK: {
      type: 'SLACK',
      title: 'Slack',
      description: 'Post messages to Slack channels',
      isAI: false,
      onClick: () => {},
      isDisabled: true
    },
    PHONE: {
      type: 'PHONE',
      title: 'Phone Call',
      description: 'Automate phone call setups',
      isAI: false,
      onClick: () => {},
      isDisabled: true
    }
  }

  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={3}>
      <ChooseTypeCard {...cardMapper[values?.type]} />
      <Field
        name={'metadata.phoneNumber'}
        render={({ input }) => (
          <GenericSelect
            label={'Select phone number'}
            options={phoneNumbersOptions}
            value={input.value}
            onChange={input.onChange}
          />
        )}
      />
      <Field name={'metadata.message'} component={MessageField} />
    </Box>
  )
}
