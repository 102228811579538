import React from 'react'
import { Box, Typography } from '@mui/material'
import { ReactComponent as SMS } from '../../../../common/icons/SMSIcon.svg'
import { ReactComponent as WhatsApp } from '../../../../common/icons/whatsUpIcon.svg'

export default function PhoneWhatsAppCard({ isHidden, metadata, type }) {
  if (isHidden) {
    return null
  }

  return (
    <Box
      sx={{
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
        padding: '16px',
        border: '1px solid #E7E7EE80',
        background: `#FCFCFC`
      }}
    >
      {type === 'SMS' ? <SMS /> : <WhatsApp />}
      <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: 14, fontWeight: 600, color: '#1B1B26' }}>
        {metadata?.phoneNumber}
      </Typography>
      <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: 13, fontWeight: 400, color: '#1B1B26' }}>
        {metadata?.message}
      </Typography>
    </Box>
  )
}
