import React, { useState, useEffect } from 'react'
import { Button, Drawer, Snackbar, TextField } from '@mui/material'

import './styles.css'
import OpenFolderIcon from 'src/common/icons/openFolder'

import AddNewGroupFrom from './components/addNewGroupForm'
import ViewGroup from './components/viewGroup'
import GroupsListItem from './components/groupsListItem'
import TabPill from 'src/common/components/TabPill'
import groupsService from 'src/services/groups.service'
import { useNavigate } from 'react-router-dom'
import useDebounce from 'src/hooks'
import LoadingIndicator from 'src/common/components/LoadingIndicator'

const STATUSES = [
  { code: 'ACTIVE', name: 'Active' },
  { code: 'ARCHIVED', name: 'Archived' }
]

export const SortHeader = ({ name, sort, onSort, width, disabled }) => {
  return (
    <div
      onClick={() => onSort(name)}
      className={'row row-center groups-list-table-sorter' + (disabled ? ' groups-list-table-sorter-disabled' : '')}
      style={{ width: width }}
    >
      <span
        style={{
          color: name == sort?.name && '#6123FF',
          marginRight: '6px'
        }}
      >
        {name}
      </span>
      {sort?.name == name && (
        <svg
          style={{
            transform: !sort.asc && 'rotate(180deg)',
            transition: '.3s'
          }}
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7.83325 3.33594V12.8359'
            stroke='#6123FF'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M3.33325 8.33594L7.83325 12.8359L12.3333 8.33594'
            stroke='#6123FF'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    </div>
  )
}

const GroupsGroupByStatus = ({ status, groups, onEdit, onView, onArchive, handleUpdate, search }) => {
  const [sortedItems, setSortedItems] = useState(groups)
  const [sortBy, setSortBy] = useState(null)

  const handleSort = field => {
    const fieldset = {
      name: 'name',
      'training pass rate': 'trainingsPassPate',
      'threads fraud rate': 'threadsFraudRate',
      'simulations pass rate': 'simulationsPassRate'
    }
    const fieldName = fieldset[field.toLowerCase()]
    setSortedItems(
      groups
        .map(el => ({
          ...el,
          modules: el.trainingModuleIds?.length || 0
        }))
        .sort((a, b) => {
          if (fieldName == 'name') {
            if (a[fieldName].toLowerCase() < b[fieldName].toLowerCase()) {
              return sortBy?.asc ? -1 : 1
            }
            if (a[fieldName].toLowerCase() > b[fieldName].toLowerCase()) {
              return sortBy?.asc ? 1 : -1
            }
            return 0
          } else {
            if (a[fieldName] < b[fieldName]) {
              return sortBy?.asc ? -1 : 1
            }
            if (a[fieldName] > b[fieldName]) {
              return sortBy?.asc ? 1 : -1
            }
            return 0
          }
        })
    )
    setSortBy({
      name: field,
      asc: field == sortBy?.name ? !sortBy.asc : true
    })
  }

  return (
    <div className=''>
      <h2
        style={{
          marginTop: '36px',
          marginBottom: '8px',
          fontFamily: 'Satoshi',
          fontSize: '24px'
        }}
      >
        {status?.name}
      </h2>
      <div className='row row-center groups-list-heading'>
        <SortHeader name='Name' sort={sortBy} onSort={handleSort} width={'55%'} />
        <SortHeader name='Simulations pass rate' sort={sortBy} onSort={handleSort} width={'15%'} />
        <SortHeader name='Training pass rate' sort={sortBy} onSort={handleSort} width={'15%'} />
        <SortHeader name='Threads fraud rate' sort={sortBy} onSort={handleSort} width={'15%'} />
      </div>
      {sortedItems
        .filter(el => el.status == status.code)
        ?.map(item => (
          <GroupsListItem
            key={item.id}
            item={item}
            onViewGroup={onView}
            onEdit={onEdit}
            onArchive={onArchive}
            search={search}
          />
        ))}
    </div>
  )
}

const Groups = () => {
  const navigate = useNavigate()

  const [groups, setGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [openCreateGroup, setOpenCreateGroup] = useState(false)
  const [uncheckedTabs, setUncheckedtabs] = useState([])

  const [successMessage, setSuccessMessage] = useState(null)

  const [search, setSearch] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const debouncedSearchTerm = useDebounce(search, 500)

  const onFilter = search => {
    setIsLoading(true)
    groupsService
      .getGroups({ searchText: search })
      .then(resp => {
        setIsLoading(false)
        setGroups(resp.data)
      })
      .catch(err => {
        console.error(err)
        setIsLoading(false)
        if (err.response.status == 401) {
          navigate('/login')
        }
      })
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true)
      onFilter(search)
    } else {
      onFilter()
    }
  }, [debouncedSearchTerm])

  const handleTabPillSelect = name => {
    setUncheckedtabs(uncheckedTabs.includes(name) ? uncheckedTabs.filter(el => el !== name) : [...uncheckedTabs, name])
  }

  useEffect(() => {
    setIsLoading(true)
    groupsService
      .getGroups({})
      .then(resp => {
        setGroups(resp.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        console.error(err)
        if (err.response.status == 401) {
          navigate('/login')
        }
      })
  }, [])

  const handleUpdate = eventType => {
    setIsLoading(true)
    groupsService.getGroups({}).then(
      resp => {
        setIsLoading(false)
        setGroups(resp.data)
        if (eventType == 'publish') {
          setSuccessMessage('Successfully published group')
        }
        if (eventType == 'archive') {
          setSuccessMessage('Successfully archived group')
        }
        if (eventType == 'unarchive') {
          setSuccessMessage('Successfully unarchived group')
        }
      },
      err => {
        setIsLoading(false)
        if (err.response.status == 401) {
          navigate('/login')
        }
      }
    )
  }

  const handleViewGroup = id => {
    setSelectedGroup(groups.find(el => el.id == id))
  }

  const handleEditGroup = id => {
    setOpenCreateGroup(true)
    setSelectedGroup(groups.find(el => el.id == id))
  }

  const handleArchiveGroup = item => {
    if (selectedGroup) {
      setSelectedGroup(null)
    }
    if (item.status == 'ARCHIVED') {
      groupsService.unArchiveGroup(item.id).then(() => {
        handleUpdate('unarchive')
      })
    } else {
      groupsService.archiveGroup(item.id).then(() => {
        handleUpdate('archive')
      })
    }
  }

  const tabs = [
    {
      id: 2,
      text: 'Active',
      itemsCount: groups?.filter(el => el.status == 'ACTIVE').length || 0
    },
    {
      id: 3,
      text: 'Archived',
      itemsCount: groups?.filter(el => el.status == 'ARCHIVED').length || 0
    }
  ]

  return (
    <div className='column' style={{ padding: '24px', height: '100vh' }}>
      <div style={{ marginBottom: '24px' }} className='row row-center'>
        <h1 style={{ marginRight: 20, marginBottom: 0, fontSize: 24 }}>Groups</h1>
        {/* <Spacer /> */}
        <TextField
          sx={{
            flex: 1,
            marginRight: '20px',
            '& .MuiOutlinedInput-root': { borderRadius: '12px' }
          }}
          size='small'
          placeholder='Search'
          onChange={e => setSearch(e.target.value)}
          value={search}
        />
        {/* <Spacer /> */}
        <Button
          variant={'contained'}
          sx={{
            background: '#6123FF',
            color: '#fff',
            textTransform: 'none',
            width: 180,
            borderRadius: '12px'
          }}
          startIcon={<OpenFolderIcon />}
          onClick={() => setOpenCreateGroup(true)}
        >
          Add New Group
        </Button>
      </div>

      <div className='row row-center' style={{ marginTop: '24px', marginBottom: '36px' }}>
        {tabs.map(el => (
          <TabPill
            key={el.id}
            checked={!uncheckedTabs.includes(el.text)}
            onSelect={handleTabPillSelect}
            text={el.text}
            amount={el.itemsCount}
          />
        ))}
      </div>

      {isLoading ? (
        <div className='column column-center' style={{ flex: 1 }}>
          <LoadingIndicator />
        </div>
      ) : (
        <>
          {search && groups?.length == 0 && (
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 250px)'
              }}
              className='column'
            >
              <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M105 33.75H72.4999C71.6886 33.75 70.8991 33.4868 70.25 33L57.25 23.25C56.6009 22.7632 55.8114 22.5 55.0001 22.5H33.75C33.2575 22.5 32.7699 22.597 32.3149 22.7854C31.86 22.9739 31.4466 23.2501 31.0983 23.5983C30.7501 23.9466 30.4739 24.36 30.2854 24.8149C30.097 25.2699 30 25.7575 30 26.25V37.5H40.0001C40.8114 37.5 41.6009 37.7632 42.25 38.25L55.25 48C55.8991 48.4868 56.6886 48.75 57.4999 48.75H90C90.4925 48.75 90.9801 48.847 91.4351 49.0354C91.89 49.2239 92.3034 49.5001 92.6517 49.8483C92.9999 50.1966 93.2761 50.61 93.4646 51.0649C93.653 51.5199 93.75 52.0075 93.75 52.5V82.5H105.417C105.854 82.5 106.288 82.4138 106.692 82.2463C107.097 82.0788 107.464 81.8333 107.774 81.5237C108.083 81.2142 108.329 80.8467 108.496 80.4423C108.664 80.0379 108.75 79.6044 108.75 79.1667V37.5C108.75 37.0075 108.653 36.5199 108.465 36.0649C108.276 35.61 108 35.1966 107.652 34.8483C107.303 34.5001 106.89 34.2239 106.435 34.0354C105.98 33.847 105.492 33.75 105 33.75Z'
                  fill='#E7E7EE'
                />
                <path
                  d='M93.75 52.5V94.1667C93.75 95.0507 93.3988 95.8986 92.7737 96.5237C92.1486 97.1488 91.3007 97.5 90.4167 97.5H18.75C17.7554 97.5 16.8016 97.1049 16.0984 96.4017C15.3951 95.6984 15 94.7446 15 93.75V41.25C15 40.2554 15.3951 39.3016 16.0984 38.5984C16.8016 37.8951 17.7554 37.5 18.75 37.5H40C40.8114 37.5 41.6009 37.7632 42.25 38.25L55.25 48C55.8991 48.4868 56.6886 48.75 57.5 48.75H90C90.9946 48.75 91.9484 49.1451 92.6517 49.8484C93.3549 50.5516 93.75 51.5054 93.75 52.5Z'
                  stroke='#E7E7EE'
                  strokeWidth='5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M30 37.5V26.25C30 25.2554 30.3951 24.3016 31.0984 23.5984C31.8016 22.8951 32.7554 22.5 33.75 22.5H55C55.8114 22.5 56.6009 22.7632 57.25 23.25L70.25 33C70.8991 33.4868 71.6886 33.75 72.5 33.75H105C105.995 33.75 106.948 34.1451 107.652 34.8484C108.355 35.5516 108.75 36.5054 108.75 37.5V79.1667C108.75 79.6044 108.664 80.0379 108.496 80.4423C108.329 80.8467 108.083 81.2142 107.774 81.5237C107.464 81.8332 107.097 82.0787 106.692 82.2463C106.288 82.4138 105.854 82.5 105.417 82.5H93.75'
                  stroke='#E7E7EE'
                  strokeWidth='5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>

              <p
                style={{
                  textAlign: 'center',
                  color: '#76767F'
                }}
              >
                No groups found for your search. <br /> Try changing your query
              </p>
            </div>
          )}
          {tabs.filter(el => !(el.itemsCount == 0 || uncheckedTabs.includes(el.text))).length == 0 && (
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 250px)'
              }}
              className='column'
            >
              <svg width='221' height='80' viewBox='0 0 221 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect y='10' width='221' height='60' rx='30' fill='#F4F5F9' />
                <path
                  d='M54.375 32.125L38.625 47.8743L30.75 40'
                  stroke='#BABBC2'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <rect x='69' y='31' width='80' height='18' fill='#E7E7EE' />
                <rect x='161' y='22' width='36' height='36' rx='18' fill='white' />
              </svg>

              <p
                style={{
                  textAlign: 'center',
                  color: '#76767F'
                }}
              >
                Select the groups you want <br /> to be displayed
              </p>
            </div>
          )}
          <div className='groups-list-container'>
            {STATUSES.map(
              el =>
                !uncheckedTabs.includes(el.name) &&
                groups?.filter(pack => pack.status == el.code).length > 0 && (
                  <GroupsGroupByStatus
                    key={el.code}
                    status={el}
                    groups={groups}
                    onEdit={handleEditGroup}
                    onView={handleViewGroup}
                    handleUpdate={handleUpdate}
                    onArchive={handleArchiveGroup}
                    search={search}
                  />
                )
            )}
          </div>
        </>
      )}
      <Drawer
        anchor='right'
        open={!!selectedGroup}
        onClose={() => {
          // setShowViewGroup(false)
          setSelectedGroup(null)
        }}
      >
        {selectedGroup && <ViewGroup item={selectedGroup} onEdit={handleEditGroup} onArchive={handleArchiveGroup} />}
      </Drawer>

      <Drawer
        anchor='right'
        open={openCreateGroup}
        onClose={() => {
          setOpenCreateGroup(false)
          setSelectedGroup(null)
        }}
      >
        <AddNewGroupFrom
          selectedId={selectedGroup?.id}
          open={openCreateGroup}
          onClose={() => {
            setOpenCreateGroup(false)
            setSelectedGroup(null)
          }}
          onCreate={updated => {
            handleUpdate()
            setOpenCreateGroup(false)
            setSelectedGroup(null)
            setSuccessMessage(updated ? 'Group is updated successfully' : 'Group is created successfully')
          }}
        />
      </Drawer>

      <Snackbar
        sx={{ '& .MuiSnackbarContent-root': { borderRadius: '8px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
        message={successMessage}
      />
    </div>
  )
}

export default Groups
