import { IconButton, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'

import '../styles.css'

import Spacer from 'src/common/components/Spacer'
import Highlighter from 'react-highlight-words'
import workflowsService from 'src/services/workflows.service'
import CyberSwitchV2 from 'src/common/components/SwitchV2'

export const WorkflowsListItem = ({ item, onEdit = () => {}, onView = () => {}, onDelete = () => {}, search }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [active, setActive] = useState(item.status == 'RUNNING')
  const [disabled, setDisabled] = useState(false)
  const openMenu = Boolean(anchorEl)

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleStatusUpdate = () => {
    //optimistic
    setActive(prev => !prev)

    setDisabled(true)
    workflowsService
      .updateStatus(item.id, active ? 'PAUSED' : 'RUNNING')
      .then(() => {
        setDisabled(false)
      })
      .catch(err => {
        setActive(prev => !prev)
        console.error(err)
        setDisabled(false)
      })
  }

  let itemActions = []
  item.actions.findIndex(el => el.type == 'WEBHOOK') !== -1 && itemActions.push('Send webhook')
  item.actions.findIndex(el => el.type == 'TRAINING_PACKAGE') !== -1 && itemActions.push('Enroll to Training Package')
  item.actions.findIndex(el => el.type == 'NOTIFICATION') !== -1 && itemActions.push('Send notification')
  item.actions.findIndex(el => el.type == 'SLACK') !== -1 && itemActions.push('Send training in Slack')

  return (
    <div key={item.id} className={`wf-item column ${active ? '' : ' workflow-inactive'}`}>
      <div className='row'>
        <div className='column' style={{ paddingRight: '12px' }}>
          {item.trigger.service && item.trigger.service == 'CYBERLIFT' && (
            <img src={'images/wf3.png'} width={32} alt='' />
          )}
          {item.trigger.service && item.trigger.service == 'HAVE_I_BEEN_PWNED' && (
            <img src={'images/wf4.png'} width={32} alt='' />
          )}
          {item.trigger.service && item.trigger.service == 'GMAIL' && <img src={'images/wf5.png'} width={32} alt='' />}
          {item.trigger.service && item.trigger.service == 'OUTLOOK' && (
            <img src={'images/wf6.png'} width={32} alt='' />
          )}
        </div>
        <div className='column' style={{ width: '45%' }}>
          <p
            style={{
              fontWeight: '600',
              marginBottom: '6px',
              cursor: 'pointer',
              marginRight: 20
            }}
            className='package-description'
            onClick={() => onView(item.id)}
          >
            <Highlighter
              highlightClassName='highlight-text'
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={item?.name || ''}
            />
          </p>
          {/* <Tooltip title={item.description.length > 45 ? item.description : null} arrow> */}

          <p style={{ fontSize: '13px', marginRight: 20 }} className='package-description'>
            <Highlighter
              highlightClassName='highlight-text'
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={itemActions.join(', ')} //
            />
          </p>
          {/* </Tooltip> */}
        </div>
        <Spacer />
        <div className='row row-center' style={{ paddingRight: 60 }}>
          <div className='column'>
            <p style={{ color: '#76767F', fontWeight: 600, fontSize: '13px' }}>Users</p>
            <p style={{ color: '#1B1B26', fontWeight: 500, fontSize: '20px' }}>{item?.usersCount}</p>
            <p></p>
          </div>
        </div>
        <div className='row row-center' style={{ paddingRight: 60 }}>
          <div className='column'>
            <p style={{ color: '#76767F', fontWeight: 600, fontSize: '13px' }}>Triggered users</p>
            <div className='row row-center'>
              <p style={{ color: item?.triggeredCount ? '#D34EF2' : '#76767F', fontWeight: 500, fontSize: '20px' }}>
                {item?.triggeredCount || '—'}
              </p>
              {!!item?.triggeredCount && (
                <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    opacity='0.2'
                    d='M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z'
                    fill='#D54FF4'
                  />
                  <path
                    opacity='0.5'
                    d='M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z'
                    stroke='#D54FF4'
                    strokeMiterlimit='10'
                  />
                  <path
                    d='M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z'
                    stroke='#D54FF4'
                    strokeMiterlimit='10'
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
        <div className='row row-center'>
          <CyberSwitchV2 disabled={disabled} checked={active} onChange={handleStatusUpdate} />
        </div>

        <div style={{ justifyContent: 'flex-end' }} className='row row-center'>
          <IconButton onClick={handleOpenMenu}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z'
                fill='#1B1B26'
              />
              <path
                d='M12 7.125C12.6213 7.125 13.125 6.62132 13.125 6C13.125 5.37868 12.6213 4.875 12 4.875C11.3787 4.875 10.875 5.37868 10.875 6C10.875 6.62132 11.3787 7.125 12 7.125Z'
                fill='#1B1B26'
              />
              <path
                d='M12 19.125C12.6213 19.125 13.125 18.6213 13.125 18C13.125 17.3787 12.6213 16.875 12 16.875C11.3787 16.875 10.875 17.3787 10.875 18C10.875 18.6213 11.3787 19.125 12 19.125Z'
                fill='#1B1B26'
              />
            </svg>
          </IconButton>
          <Menu
            sx={{
              '& .MuiPaper-root': {
                backgroundColor: '#303051',
                color: '#ffffff',
                width: '200px',
                borderRadius: '12px'
              },
              '& li': {
                opacity: '0.7',
                fontFamily: 'Plus Jakarta Sans',
                paddingLeft: '24px'
              },
              '& li:hover': {
                opacity: '1'
              }
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem
              onClick={() => {
                onView(item.id)
                setAnchorEl(null)
              }}
            >
              <svg
                style={{ marginRight: '10px' }}
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g opacity='0.76'>
                  <path
                    opacity='0.2'
                    d='M12 5.25C4.5 5.25 1.5 12.0007 1.5 12.0007C1.5 12.0007 4.5 18.75 12 18.75C19.5 18.75 22.5 12.0007 22.5 12.0007C22.5 12.0007 19.5 5.25 12 5.25ZM12 15.7508C11.2583 15.7508 10.5333 15.5308 9.91661 15.1188C9.29993 14.7067 8.81928 14.1211 8.53545 13.4358C8.25162 12.7506 8.17736 11.9966 8.32205 11.2692C8.46675 10.5418 8.8239 9.87357 9.34835 9.34913C9.8728 8.82468 10.541 8.46753 11.2684 8.32283C11.9958 8.17814 12.7498 8.2524 13.4351 8.53623C14.1203 8.82006 14.706 9.30071 15.118 9.91739C15.5301 10.5341 15.75 11.2591 15.75 12.0008C15.75 12.9953 15.3549 13.9492 14.6516 14.6524C13.9484 15.3557 12.9946 15.7508 12 15.7508Z'
                    fill='white'
                  />
                  <path
                    d='M12 5.25C4.5 5.25 1.5 12.0007 1.5 12.0007C1.5 12.0007 4.5 18.75 12 18.75C19.5 18.75 22.5 12.0007 22.5 12.0007C22.5 12.0007 19.5 5.25 12 5.25Z'
                    stroke='white'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z'
                    stroke='white'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </g>
              </svg>
              View workflow
            </MenuItem>
            <MenuItem
              onClick={() => {
                // onEdit(item.id)
                onEdit(item.id)
                setAnchorEl(null)
              }}
            >
              <svg
                style={{ marginRight: '10px' }}
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g opacity='0.6'>
                  <path
                    opacity='0.2'
                    d='M12.75 6.00184L18 11.2518L20.4697 8.78217C20.6103 8.64152 20.6893 8.45075 20.6893 8.25184C20.6893 8.05293 20.6103 7.86216 20.4697 7.72151L16.2803 3.53217C16.1397 3.39152 15.9489 3.3125 15.75 3.3125C15.5511 3.3125 15.3603 3.39152 15.2197 3.53217L12.75 6.00184Z'
                    fill='white'
                  />
                  <path d='M12.75 6L18 11.25' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                  <path
                    d='M9 20.2518H4.5C4.30109 20.2518 4.11032 20.1728 3.96967 20.0322C3.82902 19.8915 3.75 19.7008 3.75 19.5018V15.3125C3.75 15.214 3.7694 15.1165 3.80709 15.0255C3.84478 14.9345 3.90003 14.8518 3.96967 14.7822L15.2197 3.53217C15.3603 3.39152 15.5511 3.3125 15.75 3.3125C15.9489 3.3125 16.1397 3.39152 16.2803 3.53217L20.4697 7.72151C20.6103 7.86216 20.6893 8.05293 20.6893 8.25184C20.6893 8.45075 20.6103 8.64152 20.4697 8.78217L9 20.2518Z'
                    stroke='white'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </g>
              </svg>
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                onDelete(item.id)
                setAnchorEl(null)
              }}
            >
              <svg
                style={{ marginRight: '10px' }}
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M7.5 15.75L18 5.25' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M18 14.625V5.25H8.625' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M3.75 20.25H20.25' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
              </svg>
              Delete
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  )
}
