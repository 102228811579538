import React, { useContext, useEffect, useRef, useState } from 'react'
import './styles.css'
import { styled } from '@mui/material/styles'
import { Button, ButtonGroup, ClickAwayListener, Collapse, Grow, Paper, Popper, TextField } from '@mui/material'

import CustomCheckbox from 'src/common/components/CustomCheckbox'
import CyberSwitch from 'src/common/components/Switch'
import Spacer from 'src/common/components/Spacer'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import trainingsService from 'src/services/trainings.service'
import Info from 'src/common/components/Info'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import EditIcon from 'src/common/icons/editIcon'
import { ValueAndUnit } from 'src/pages/overview'
import { toggleElement } from 'src/heplers'
import { ChooseUsersGroups } from 'src/common/components/ChooseUsersGroups'
import RadioSelect from 'src/common/components/RadioSelect'
import { SettingsContext, SnackBarContext } from 'src/App'

export const TableBigNum = ({ color, num }) => (
  <span
    style={{
      fontFamily: 'Satoshi',
      fontSize: '24px',
      fontWeight: '500',
      color: color ? color : num < 50 && '#DB0F34'
    }}
  >
    {num ? num : '0'}
  </span>
)

export const SortHeader = ({ name, sort, onSort, width }) => {
  return (
    <div onClick={() => onSort(name)} className='row row-center groups-list-table-sorter' style={{ width: width }}>
      <span
        style={{
          color: name == sort?.name && '#6123FF',
          marginRight: '6px'
        }}
      >
        {name}
      </span>
      {sort?.name == name && (
        <svg
          style={{
            transform: !sort.asc && 'rotate(180deg)',
            transition: '.3s'
          }}
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7.83325 3.33594V12.8359'
            stroke='#6123FF'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M3.33325 8.33594L7.83325 12.8359L12.3333 8.33594'
            stroke='#6123FF'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    </div>
  )
}

const ModulesList = ({ allModules, form, setForm }) => {
  const [collapsedTopics, setCollapsedTopics] = useState([])
  return (
    <>
      {allModules &&
        allModules.map(el => (
          <div key={el.trainingId}>
            <Collapse in={collapsedTopics?.includes(el.trainingId)} collapsedSize={59}>
              <div
                className='row row-center pointer collapsible-topic-title'
                onClick={() => setCollapsedTopics(toggleElement(collapsedTopics, el.trainingId))}
              >
                <h3
                  style={{
                    fontFamily: 'Satoshi',
                    fontSize: '18px',
                    marginBottom: '20px',
                    marginTop: '20px'
                  }}
                >
                  {el.topic}
                </h3>
                <Spacer />
                <div style={{ paddingRight: 20 }}>
                  <svg
                    style={{
                      transition: '0.4s',
                      transform: collapsedTopics?.includes(el.trainingId) && 'rotate(180deg)'
                    }}
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5.33337L8 10.3334L13 5.33337'
                      stroke='#1B1B26'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </div>
              {el.modules.map(moduleItem => (
                <div
                  key={moduleItem.trainingModuleId}
                  className={`row row-center module-element ${el.active && 'module-element-active'}`}
                >
                  <CyberSwitch
                    disabled={form.status == 'ARCHIVED'}
                    onChange={() => {
                      setForm({
                        ...form,
                        trainingModuleIds: form?.trainingModuleIds?.includes(moduleItem.trainingModuleId)
                          ? form.trainingModuleIds.filter(el => el !== moduleItem.trainingModuleId)
                          : [...form.trainingModuleIds, moduleItem.trainingModuleId]
                      })
                    }}
                    checked={form.trainingModuleIds?.includes(moduleItem.trainingModuleId)}
                  />

                  <p style={{ marginLeft: '10px' }}>{moduleItem.module}</p>
                  <Spacer />
                </div>
              ))}
            </Collapse>
          </div>
        ))}
    </>
  )
}

// ModulesList.displayName = 'ModulesList';

const LoginInput = styled(TextField)(() => ({
  border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px'
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px'
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px'
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  }
}))

const PackageCreate = ({ selectedId, isEdit = false, handleClose = () => {} }) => {
  const [form, setForm] = useState({ daysToComplete: 30, mandatory: true })
  const [loading, setLoading] = useState(false)
  const [allModules, setAllModules] = useState(null)
  // const [pack, setPack] = useState(null)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [errors, setErrors] = useState(null)

  const [openMainInfo, setOpenMainInfo] = useState(true)
  const [openModulesForm, setOpenModulesForm] = useState(false)
  const [openUsersStep, setOpenUsersStep] = useState(false)

  const [selectedUsers, setSelectedUsers] = useState([])

  const [{ companyLanguage }] = useContext(SettingsContext)
  const [language, setLang] = useState(companyLanguage)
  useEffect(() => {
    setLang(companyLanguage)
  }, [companyLanguage])

  const { setErrorMessage } = useContext(SnackBarContext)

  useEffect(() => {
    if (selectedId && isEdit) {
      setLoading(true)
      trainingsService.getPackage(selectedId).then(resp => {
        setLoading(false)
        // setPack(resp.data)
        setForm({
          ...resp.data,
          ...(resp.data?.startDate && {
            startDate: new Date(resp.data?.startDate)
          }),
          ...(resp.data?.endDate && {
            endDate: new Date(resp.data?.endDate)
          })
        })
        setSelectedUsers(resp.data.userIds)
      })
    } else {
      setForm({
        trainingModuleIds: [],
        startDate: new Date(),
        endDate: dayjs(new Date()).add(1, 'month'),
        groupIds: [],
        daysToComplete: 30,
        mandatory: true
      })
    }
  }, [])

  useEffect(() => {
    Promise.all([trainingsService.getAllModules(language)]).then(values => {
      const [modulesData] = values
      setAllModules(modulesData.data)
    })
  }, [language])

  const handleSaveForm = (withPublish = false) => {
    const packageBody = {
      name: form.name.replace(/^\s+/, ''),
      description: form.description.replace(/^\s+/, ''),
      ...(form.startDate && { startDate: form.startDate }),
      ...(form.endDate && { endDate: form.endDate }),
      daysToComplete: form.daysToComplete,
      graded: form.graded,
      status: form.status,
      mandatory: form.mandatory,
      retrainingAllowed: form.retrainingAllowed,
      sendNotifications: form.sendNotifications,
      trainingModuleIds: form.trainingModuleIds || [],
      groupIds: form.groupIds || [],
      userIds: selectedUsers
    }

    if (!packageBody.name) {
      setErrors(errors ? { ...errors, name: 'Name is required' } : { name: 'Name is required' })
      return
    }
    if (!packageBody.description) {
      setErrors(
        errors ? { ...errors, description: 'Description is required' } : { description: 'Description is required' }
      )
      return
    }

    if (isEdit) {
      trainingsService
        .updatePackage(selectedId, { ...form, userIds: selectedUsers, status: withPublish ? 'PUBLISHED' : 'DRAFT' })
        .then(() => {
          handleClose('updated')
        })
        .catch(err => {
          if (err) {
            setErrorMessage(err.response.data.description)
            if (err.response.data.code == 'TRAINING_PACKAGE_ERROR_003') {
              setErrors({ name: err.response.data.description })
            }
            setOpenMainInfo(true)
            setOpenModulesForm(false)
            setOpenUsersStep(true)
          } else {
            setErrorMessage('Something went wrong')
          }
        })
    } else {
      trainingsService
        .addPackage({
          ...packageBody,
          status: withPublish ? 'PUBLISHED' : 'DRAFT'
        })
        .then(() => {
          handleClose('created')
        })
        .catch(err => {
          if (err) {
            setErrorMessage(err.response.data.description)
            if (err.response.data.code == 'TRAINING_PACKAGE_ERROR_003') {
              setErrors({ name: err.response.data.description })
            }
            setOpenMainInfo(true)
            setOpenModulesForm(false)
            setOpenUsersStep(true)
          } else {
            setErrorMessage('Something went wrong')
          }
        })
    }
  }

  // const toggleMainInfo = () => {
  //   setOpenMainInfo(!openMainInfo)
  // }
  const handleMainNextStep = () => {
    setOpenMainInfo(false)
    setOpenModulesForm(true)
  }

  const handleEditMainInfo = () => {
    setOpenMainInfo(true)
    setOpenModulesForm(false)
  }
  const handleEditModules = () => {
    setOpenMainInfo(false)
    setOpenModulesForm(true)
    setOpenUsersStep(false)
  }
  const handleEditGroups = () => {
    setOpenMainInfo(false)
    setOpenModulesForm(false)
    setOpenUsersStep(true)
  }

  const handleModulesPrevStep = () => {
    setOpenMainInfo(true)
    setOpenModulesForm(false)
  }
  const handleModulesNextStep = () => {
    setOpenMainInfo(false)
    setOpenModulesForm(false)
    setOpenUsersStep(true)
  }
  const handleGroupsPrevStep = () => {
    setOpenModulesForm(true)
    setOpenUsersStep(false)
  }
  const handleGroupsNextStep = () => {
    setOpenUsersStep(false)
  }

  const handleGroupSelect = (id, bulk) => {
    if (Array.isArray(id)) {
      let newItems = form.groupIds
      if (typeof bulk !== 'undefined') {
        id.forEach(element => {
          newItems = bulk
            ? newItems.includes(element)
              ? newItems.filter(el => el !== element)
              : newItems
            : [...newItems, element]
        })
      }

      setForm({ ...form, groupIds: newItems })
    } else {
      setForm({ ...form, groupIds: toggleElement(form.groupIds, id) })
    }
  }
  const handleUserSelect = (id, bulk) => {
    if (Array.isArray(id)) {
      let newItems = selectedUsers
      if (typeof bulk !== 'undefined') {
        id.forEach(element => {
          newItems = bulk
            ? newItems.includes(element)
              ? newItems.filter(el => el !== element)
              : newItems
            : [...newItems, element]
        })
      }

      setSelectedUsers(newItems)
    } else {
      setSelectedUsers(toggleElement(selectedUsers, id))
    }
  }

  const mainFormInvalid = !form.name || !form.description
  const modulesFormInvalid = !form.trainingModuleIds?.length
  const groupsFormInvalid = !form.groupIds?.length && !selectedUsers?.length
  const formInvalid = !form.trainingModuleIds?.length || !form.name || !form.description || groupsFormInvalid

  const startMinDate = selectedId ? null : dayjs()

  return (
    <>
      <div
        style={{
          height: 'calc(100vh - 110px)',
          width: '1040px',
          overflowY: 'auto'
        }}
      >
        <div className='column' style={{ padding: '32px' }}>
          <p className='drawer-title'>{isEdit ? 'Edit' : 'Create new'} package</p>
          <Collapse
            sx={{
              background: '#fff',
              borderRadius: '10px',
              padding: '12px 32px 32px 32px',
              marginBottom: '16px'
            }}
            in={openMainInfo}
            collapsedSize={155}
          >
            <div className='column'>
              {!openMainInfo && (
                <div
                  className='row'
                  style={{
                    height: '120px',
                    padding: '10px 0px'
                  }}
                >
                  <div
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '24px',
                      background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '16px'
                    }}
                  >
                    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M15.1876 5.05469L7.31254 12.7267L3.375 8.89086'
                        stroke='white'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>

                  <div
                    style={{
                      marginRight: '36px',
                      width: '430px'
                    }}
                    className='column'
                  >
                    <p
                      style={{
                        fontFamily: 'Satoshi',
                        fontSize: '24px',
                        marginBottom: '10px',
                        flexShrink: 0
                      }}
                      className='text-shrink-1'
                    >
                      {form.name}
                    </p>
                    <p
                      style={{
                        color: '#76767F',
                        marginBottom: '12px',
                        flexShrink: 0
                      }}
                      className='text-shrink-1'
                    >
                      {form.description}
                    </p>
                    <div className='row'>
                      {form.retrainingAllowed && (
                        <div
                          style={{
                            padding: '8px 12px',
                            background: '#EBEFFF',
                            borderRadius: '8px',
                            marginRight: '10px'
                          }}
                        >
                          Retake
                        </div>
                      )}
                      {form.mandatory && (
                        <div
                          style={{
                            padding: '8px 12px',
                            background: '#EBEFFF',
                            borderRadius: '8px',
                            marginRight: '10px'
                          }}
                        >
                          Mandatory
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row'>
                    <svg
                      style={{ margin: '5px 10px' }}
                      width='8'
                      height='34'
                      viewBox='0 0 8 34'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M4 4V32' stroke='#C7EFF4' />
                      <circle cx='4' cy='4' r='4' fill='#C7EFF4' />
                      <circle cx='4' cy='30' r='4' fill='#C7EFF4' />
                    </svg>

                    <div className='column'>
                      <p style={{ marginBottom: '10px' }}>
                        {form.startDate ? dayjs(form.startDate).format('MMM DD, YYYY') : 'no start date'}
                      </p>
                      <p>{form.endDate ? dayjs(form.endDate).format('MMM DD, YYYY') : 'no end date'}</p>
                    </div>
                  </div>

                  <Spacer />

                  <div>
                    <Button
                      variant='outlined'
                      onClick={handleEditMainInfo}
                      sx={{
                        borderColor: '#E7E7EE',
                        borderRadius: '12px',
                        color: '#6123FF',
                        textTransform: 'none',
                        flex: '1',
                        mr: '12px'
                      }}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
              )}
              <p className='drawer-subtitle'>Main info</p>
              <LoginInput
                error={errors?.name}
                disabled={form.status == 'ARCHIVED'}
                sx={{ borderRadius: '20px', mb: '12px' }}
                label='Name'
                variant='standard'
                value={form?.name || ''}
                onChange={e => {
                  setForm({ ...form, name: e.target.value })
                  setErrors(null)
                }}
              />
              {errors && errors?.name && (
                <p
                  style={{
                    color: '#B40020',
                    margin: '-4px 0px 10px 16px'
                  }}
                >
                  {errors.name}
                </p>
              )}
              <LoginInput
                error={errors?.description}
                disabled={form.status == 'ARCHIVED'}
                sx={{ borderRadius: '20px', mb: '25px' }}
                label='Description'
                variant='standard'
                value={form?.description || ''}
                onChange={e => {
                  setForm({
                    ...form,
                    description: e.target.value
                  })
                  setErrors(null)
                }}
              />
              {errors && errors?.description && (
                <p
                  style={{
                    color: '#B40020',
                    margin: '-4px 0px 10px 16px'
                  }}
                >
                  {errors.description}
                </p>
              )}

              <div className='row'>
                <div style={{ marginRight: '30px' }} className='column'>
                  <div className='row row-center'>
                    <CustomCheckbox
                      disabled={form.status == 'ARCHIVED'}
                      onClick={() =>
                        setForm({
                          ...form,
                          retrainingAllowed: !form.retrainingAllowed
                        })
                      }
                      checked={form?.retrainingAllowed || false}
                    />
                    <p>Employees can retake the training</p>
                  </div>
                  <div className='row row-center'>
                    <CustomCheckbox
                      disabled={form.status == 'ARCHIVED'}
                      onClick={() =>
                        setForm({
                          ...form,
                          mandatory: !form.mandatory
                        })
                      }
                      checked={form?.mandatory || false}
                    />
                    <p>Training package is mandatory</p>
                    <Info text={'User must pass at least 80% of package'} />
                  </div>
                </div>
                <div className='column'>
                  <div className='row row-center'>
                    <CustomCheckbox
                      disabled={form.status == 'ARCHIVED'}
                      onClick={() =>
                        setForm({
                          ...form,
                          sendNotifications: !form.sendNotifications
                        })
                      }
                      checked={form?.sendNotifications || false}
                    />
                    <p>A notification email will be sent to all employees</p>
                  </div>
                </div>
              </div>

              <p className='drawer-subtitle'>Planned date</p>

              <div className='row row-center'>
                <DatePicker
                  minDate={startMinDate}
                  format={'DD/MM/YYYY'}
                  disabled={form.status == 'ARCHIVED'}
                  sx={{
                    marginRight: '20px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '20px'
                    }
                  }}
                  value={form.startDate ? dayjs(form.startDate) : null}
                  label='Start Date'
                  onChange={e =>
                    setForm({
                      ...form,
                      startDate: dayjs(e).format('YYYY-MM-DD')
                    })
                  }
                />
                <DatePicker
                  format={'DD/MM/YYYY'}
                  minDate={dayjs(form.startDate)}
                  disabled={form.status == 'ARCHIVED'}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '20px'
                    }
                  }}
                  value={form.endDate ? dayjs(form.endDate) : null}
                  label='End Date'
                  onChange={e =>
                    setForm({
                      ...form,
                      endDate: dayjs(e).format('YYYY-MM-DD')
                    })
                  }
                />
              </div>

              <div className='row row-center'>
                <p className='drawer-subtitle'>Max Days to complete</p>
                <Info
                  text={
                    "Enter the maximum number of days users have to complete this training after it starts. During this period, users will receive periodic notifications to remind them of the training's progress and deadlines. If the training is not completed within this timeframe, it may be marked as expired"
                  }
                />
              </div>

              <div className='row row-center'>
                <LoginInput
                  error={errors?.daysToComplete}
                  disabled={form.status == 'ARCHIVED'}
                  sx={{ borderRadius: '20px', mb: '25px' }}
                  label='Days count'
                  variant='standard'
                  type='number'
                  value={form.daysToComplete}
                  onChange={e => {
                    setForm({
                      ...form,
                      daysToComplete: e.target.value
                    })
                    setErrors(null)
                  }}
                />
              </div>

              <div
                style={{
                  height: '1px',
                  width: '100%',
                  background: '#F1F1F5',
                  margin: '20px 0px'
                }}
              ></div>

              <div className='row' style={{ justifyContent: 'flex-end' }}>
                <Button
                  disabled={mainFormInvalid}
                  variant={'contained'}
                  onClick={handleMainNextStep}
                  sx={{
                    background: '#6123FF',
                    borderRadius: '12px',
                    fontFamily: 'Plus Jakarta Sans',
                    color: '#fff',
                    textTransform: 'none',
                    width: 270
                  }}
                >
                  Next Step
                </Button>
              </div>
            </div>
          </Collapse>

          <Collapse
            sx={{
              background: '#fff',
              borderRadius: '10px',
              padding: '12px 32px 32px 32px',
              marginBottom: '16px'
            }}
            in={openModulesForm}
            collapsedSize={80}
          >
            {!openModulesForm && modulesFormInvalid && (
              <>
                <div className='row row-center'>
                  <div
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '24px',
                      background: '#6123FF',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '16px',
                      color: '#fff',
                      fontWeight: '600'
                    }}
                  >
                    2
                  </div>
                  <p className='drawer-subtitle'>Includes Modules</p>
                </div>
              </>
            )}
            {!openModulesForm && !modulesFormInvalid && (
              <>
                <div className='row row-center'>
                  <div style={{ width: '430px' }} className='row row-center'>
                    <div
                      style={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '24px',
                        background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '16px'
                      }}
                    >
                      <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M15.1876 5.05469L7.31254 12.7267L3.375 8.89086'
                          stroke='white'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <p className='drawer-subtitle'>Modules</p>
                  </div>
                  <div>
                    <ValueAndUnit val={form.trainingModuleIds.length} unit={'Includes'} />
                  </div>
                  <Spacer />

                  <div>
                    <Button
                      variant='outlined'
                      onClick={handleEditModules}
                      sx={{
                        borderColor: '#E7E7EE',
                        borderRadius: '12px',
                        color: '#6123FF',
                        textTransform: 'none',
                        flex: '1',
                        mr: '12px'
                      }}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
              </>
            )}
            <div className='column'>
              <div className='row row-center'>
                <p className='drawer-subtitle'>Includes Modules</p>
                <Spacer />
                <RadioSelect
                  style={{ marginRight: 20 }}
                  options={['English', 'Polish']}
                  value={language}
                  onSelectOption={el => {
                    setLang(el)
                  }}
                />
              </div>

              <p
                style={{
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#76767F',
                  marginBottom: '24px'
                }}
              >
                <span
                  style={{
                    fontFamily: 'Satoshi',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '24px',
                    lineHeight: '120%',
                    color: '#1B1B26',
                    marginRight: '10px'
                  }}
                >
                  {form?.trainingModuleIds?.length || '0'}/{allModules?.map(el => el.modules).flat().length}
                </span>
                active modules
              </p>

              <div
                style={{
                  maxHeight: '500px',
                  overflow: 'auto'
                }}
              >
                {openModulesForm && <ModulesList allModules={allModules} form={form} setForm={setForm} />}
              </div>

              <div
                style={{
                  height: '1px',
                  width: '100%',
                  background: '#F1F1F5',
                  margin: '20px 0px'
                }}
              ></div>

              <div className='row row-center'>
                <Button
                  variant={'outlined'}
                  onClick={handleModulesPrevStep}
                  sx={{
                    borderRadius: '12px',
                    fontFamily: 'Plus Jakarta Sans',
                    textTransform: 'none',
                    width: 270
                  }}
                >
                  Previous Step
                </Button>
                <Spacer />
                <Button
                  disabled={modulesFormInvalid}
                  variant={'contained'}
                  onClick={handleModulesNextStep}
                  sx={{
                    background: '#6123FF',
                    borderRadius: '12px',
                    fontFamily: 'Plus Jakarta Sans',
                    color: '#fff',
                    textTransform: 'none',
                    width: 270
                  }}
                >
                  Next Step
                </Button>
              </div>
            </div>
          </Collapse>

          <Collapse
            sx={{
              background: '#fff',
              borderRadius: '10px',
              padding: '12px 32px 32px 32px'
            }}
            in={openUsersStep}
            collapsedSize={80}
          >
            {!openUsersStep && groupsFormInvalid && (
              <>
                <div className='row row-center'>
                  <div
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '24px',
                      background: '#6123FF',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '16px',
                      color: '#fff',
                      fontWeight: '600'
                    }}
                  >
                    3
                  </div>
                  <p className='drawer-subtitle'>Groups/Users</p>
                </div>
              </>
            )}
            {!openUsersStep && !groupsFormInvalid && (
              <>
                <div className='row row-center'>
                  <div style={{ width: '430px' }} className='row row-center'>
                    <div
                      style={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '24px',
                        background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '16px'
                      }}
                    >
                      <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M15.1876 5.05469L7.31254 12.7267L3.375 8.89086'
                          stroke='white'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                    <p className='drawer-subtitle'>Groups/Users</p>
                  </div>
                  <div className='row'>
                    {form.groupIds.length !== 0 && <ValueAndUnit val={form.groupIds.length || 0} unit={'Groups'} />}
                    {form.groupIds.length !== 0 && selectedUsers.length !== 0 && <div style={{ width: 10 }}></div>}
                    {selectedUsers.length !== 0 && <ValueAndUnit val={selectedUsers.length} unit={'Users'} />}
                  </div>
                  <Spacer />

                  <div>
                    <Button
                      variant='outlined'
                      onClick={handleEditGroups}
                      sx={{
                        borderColor: '#E7E7EE',
                        borderRadius: '12px',
                        color: '#6123FF',
                        textTransform: 'none',
                        flex: '1',
                        mr: '12px'
                      }}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
              </>
            )}

            <div className='column'>
              <ChooseUsersGroups
                selectedGroups={form.groupIds}
                selectedUsers={selectedUsers}
                onSelectGroup={handleGroupSelect}
                onSelectUser={handleUserSelect}
              />

              <div
                style={{
                  height: '1px',
                  width: '100%',
                  background: '#F1F1F5',
                  margin: '20px 0px'
                }}
              ></div>

              <div className='row row-center'>
                <Button
                  variant={'outlined'}
                  onClick={handleGroupsPrevStep}
                  sx={{
                    borderRadius: '12px',
                    fontFamily: 'Plus Jakarta Sans',
                    textTransform: 'none',
                    width: 270
                  }}
                >
                  Previous Step
                </Button>
                <Spacer />
                <Button
                  disabled={groupsFormInvalid}
                  variant={'contained'}
                  onClick={handleGroupsNextStep}
                  sx={{
                    background: '#6123FF',
                    borderRadius: '12px',
                    fontFamily: 'Plus Jakarta Sans',
                    color: '#fff',
                    textTransform: 'none',
                    width: 270
                  }}
                >
                  Next Step
                </Button>
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <div
        style={{
          bottom: '0',
          right: '0',
          background: '#fff',
          padding: '25px 32px'
        }}
        className='row'
      >
        <div style={{ width: '50%', padding: '0px 10px' }}>
          <Button
            sx={{
              color: '#6123FF',
              borderColor: '#E7E7EE',
              textTransform: 'none',
              height: '60px',
              width: '100%'
            }}
            onClick={() => handleClose()}
            variant={'outlined'}
          >
            Cancel
          </Button>
        </div>
        <div style={{ width: '50%', padding: '0px 10px' }}>
          <ButtonGroup
            sx={{
              color: '#fff',
              textTransform: 'none',
              height: '60px',
              width: '100%'
            }}
            variant='contained'
            ref={anchorRef}
            aria-label='split button'
          >
            <Button
              sx={{
                color: '#fff',
                background: '#6123FF',
                textTransform: 'none',
                width: '100%'
              }}
              disabled={formInvalid}
              onClick={() => handleSaveForm(false)}
            >
              Save Training Package
            </Button>
            <Button
              sx={{
                color: '#fff',
                background: '#6123FF',
                textTransform: 'none'
              }}
              disabled={formInvalid || form.status == 'ARCHIVED'}
              size='small'
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label='select merge strategy'
              aria-haspopup='menu'
              onClick={() => {
                setOpen(prevOpen => !prevOpen)
              }}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1
            }}
            open={open}
            anchorEl={anchorRef.current}
            placement='top-end'
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Button
                      onClick={() => {
                        setOpen(false)
                        handleSaveForm(true)
                      }}
                      style={{
                        color: '#fff',
                        background: '#6123FF',
                        textTransform: 'none',
                        width: anchorRef?.current?.clientWidth + 'px' || '430px',
                        height: '60px',
                        justifyContent: 'flex-start',
                        paddingLeft: '154px'
                      }}
                    >
                      Save And Publish
                    </Button>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </>
  )
}

export default PackageCreate
