import React from 'react'
import { MenuItem, TextField } from '@mui/material'

export default function GenericSelect({ options, onChange, value, label, disabled, ...rest }) {
  return (
    <TextField
      sx={{
        width: 240,
        fontSize: 16,
        backgroundColor: '#FFFFFF',
        fontWeight: 400,
        fontFamily: 'Plus Jakarta Sans',
        '& .MuiOutlinedInput-root': {
          fontSize: 16,
          fontWeight: 400,
          fontFamily: 'Plus Jakarta Sans',
          borderRadius: '10px'
        },
        '& .MuiOutlinedInput-input': {
          padding: '13px 14px 14px'
        }
      }}
      select
      label={label}
      labelId={value}
      value={value}
      onChange={onChange}
      disabled={disabled}
      {...rest}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
