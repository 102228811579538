import React from 'react'
import { Bounce, toast } from 'react-toastify'
import DoneIcon from '@mui/icons-material/Done'
import { Box } from '@mui/material'

export default function customToastWithAlert({ message, type }) {
  return toast(
    <Box width={'400px'} display={'flex'} alignItems={'center'} columnGap={2}>
      {type !== 'error' && <DoneIcon />}
      {message}
    </Box>,
    {
      theme: 'dark',
      position: 'bottom-center',
      hideProgressBar: true,
      closeButton: false,
      transition: Bounce
    }
  )
}
