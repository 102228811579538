import React from 'react'
import { Box, Typography } from '@mui/material'
import PersonalizedEmailCard from './PersonalizedEmailCard'
import PhoneWhatsAppCard from './PhoneWhatsAppCard'
import CustomTemplateList from './CustomTemplateList'

export default function TemplatesPreview({ data }) {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      rowGap={2}
      height={'calc(100vh - 210px)'}
      p={'32px'}
      bgcolor={'#fff'}
      overflow={'auto'}
      sx={{
        borderRadius: '20px',
        border: '1px solid #1C1C270D',
        '::-webkit-scrollbar': {
          width: '6px',
          height: '6px'
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#DBDBE6',
          borderRadius: '2px',
          '&:hover': {
            backgroundColor: '#DBDBE6'
          }
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: 'transparent'
        },
        scrollbarWidth: 'thin',
        scrollbarColor: '#DBDBE6 transparent'
      }}
    >
      <Typography sx={{ fontFamily: 'Satoshi', fontSize: 24, fontWeight: 500, color: '#1B1B26' }}>
        {getTemplateTitle(data?.type, data?.templates?.length)}
      </Typography>
      <PersonalizedEmailCard isHidden={data?.type !== 'AUTOMATED_EMAIL'} />
      <PhoneWhatsAppCard
        isHidden={data?.type !== 'WHATSAPP' && data?.type !== 'SMS'}
        type={data?.type}
        metadata={data?.metadata}
      />
      <CustomTemplateList isHidden={data?.type !== 'MANUAL_EMAIL'} templates={data?.templates} />
    </Box>
  )
}

function getTemplateTitle(type, count) {
  if (type === 'MANUAL_EMAIL') {
    return `${count} Personalized Email Templates `
  }
  if (type === 'AUTOMATED_EMAIL') {
    return 'Automatic Email'
  }
  if (type === 'SMS') {
    return 'Personalized SMS'
  }
  if (type === 'WHATSAPP') {
    return 'Personalized WhatsApp message'
  }
}
