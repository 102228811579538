import React, { useContext } from 'react'
import { PageNumberContext } from './PageNumberContext'
import ChooseTypeCard from './ChooseTypeCard'
import { Field, useFormState } from 'react-final-form'
import { Box, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import GenericSelect from '../../../common/components/GenericSelect'
import dayjs from 'dayjs'
import { messagesType, timelineOptions } from './utils'

export default function SecondPageForm() {
  const { values } = useFormState()
  const { setPage } = useContext(PageNumberContext)
  const isSmsTemplate = values?.metadata?.phoneNumber
  const emailTemplatesCount = isNaN(values?.internalTemplateIds?.length + values?.customTemplateIds?.length)
    ? 0
    : values?.internalTemplateIds?.length + values?.customTemplateIds?.length
  const cardMapper = {
    AUTOMATED_EMAIL: {
      type: 'AUTOMATED_EMAIL',
      title: 'Email',
      description: 'Send automated emails',
      isAI: true,
      onChange: () => setPage(1)
    },
    MANUAL_EMAIL: {
      type: 'MANUAL_EMAIL',
      title: 'Personalized Emails',
      description: 'Craft and dispatch custom emails',
      isAI: true,
      onChange: () => setPage(1)
    },
    SMS: {
      type: 'SMS',
      title: 'SMS',
      description: 'Send text messages automatically',
      isAI: false,
      onChange: () => setPage(1)
    },
    WHATSAPP: {
      type: 'WHATSAPP',
      title: 'Whatsapp',
      description: 'Automate WhatsApp messages',
      isAI: false,
      onChange: () => setPage(1)
    },
    SLACK: {
      type: 'SLACK',
      title: 'Slack',
      description: 'Post messages to Slack channels',
      isAI: false,
      onClick: () => {},
      isDisabled: true
    },
    PHONE: {
      type: 'PHONE',
      title: 'Phone Call',
      description: 'Automate phone call setups',
      isAI: false,
      onClick: () => {},
      isDisabled: true
    },
    SMS_TEMPLATE: {
      type: 'SMS_TEMPLATE',
      title: 'Template',
      description: '',
      isAI: false,
      number: isSmsTemplate && 1,
      onChange: () => {
        setPage('SMS_TEMPLATE')
      },
      isDisabled: false
    },
    EMAIL_TEMPLATE: {
      type: 'EMAIL_TEMPLATE',
      title: 'Template',
      description: '',
      isAI: false,
      onChange: () => {
        setPage('EMAIL_TEMPLATE')
      },
      number: emailTemplatesCount,
      isDisabled: false
    }
  }

  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={3}>
      <ChooseTypeCard {...cardMapper[values?.type]} />
      {messagesType.includes(values?.type) && <ChooseTypeCard {...cardMapper['SMS_TEMPLATE']} />}
      {values?.type === 'MANUAL_EMAIL' && <ChooseTypeCard {...cardMapper['EMAIL_TEMPLATE']} />}
      <Typography color={'##76767F'} fontWeight={500} fontSize={16} fontFamily={'Plus Jakarta Sans'}>
        Choose period
      </Typography>

      <Box display={'flex'} columnGap={2}>
        <Field
          name={'startDate'}
          render={({ input, meta }) => {
            return (
              <DatePicker
                format={'DD/MM/YYYY'}
                disablePast={true}
                minDate={dayjs()}
                sx={{
                  width: 240,
                  marginRight: '20px',
                  backgroundColor: '#ffffff',
                  '& .MuiOutlinedInput-root': {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: 'Plus Jakarta Sans',
                    borderRadius: '10px'
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '12.5px 14px'
                  }
                }}
                slotProps={{
                  textField: {
                    error: meta.touched && meta.error
                  },
                  inputAdornment: { position: 'start', variant: 'standard' }
                }}
                onChangeRaw={e => e.preventDefault()}
                shouldDisableDate={date => date.isBefore(dayjs(), 'day')}
                value={dayjs(input.value)}
                label='Start Date'
                onChange={date => {
                  const selectedDate = dayjs(date)
                  if (selectedDate.isBefore(dayjs(), 'day')) {
                    input.onChange(dayjs().format('YYYY-MM-DD'))
                  } else {
                    input.onChange(selectedDate.format('YYYY-MM-DD'))
                  }
                }}
              />
            )
          }}
        />
        <Field
          name={'timeline'}
          render={({ input }) => (
            <GenericSelect
              options={timelineOptions.filter(i => i.value !== 'CUSTOM_DATE' && i.value !== 'CUSTOM_DAYS_COUNT')}
              value={input.value}
              onChange={input.onChange}
              label={'Timeline'}
            />
          )}
        />
      </Box>
    </Box>
  )
}
