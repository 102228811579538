import { Button, Drawer, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'

import './styles.css'

import { useEffect } from 'react'
import Spacer from 'src/common/components/Spacer'
import useDebounce from 'src/hooks'
import PlusIcon from 'src/common/icons/plusIcon'
import { useWorkflows } from './hooks'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import ViewWorkflow from './components/viewWorkflow'
import workflowsService from 'src/services/workflows.service'
import WorkflowCreate from './components/WorkflowCreate'
import DeleteDialog from 'src/common/components/DeleteDialog'
import { CloseDrawer } from 'src/common/icons/closeDrawer'
import { SnackBarContext } from 'src/App'
import { WorkflowsListItem } from './components/listItem'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as SearchIcon } from '../../common/icons/search.svg'
import StatusFilter from './components/Filter/FilterPopper'

export const SortHeader = ({ name, sort, onSort, width }) => {
  return (
    <div onClick={() => onSort(name)} className='row row-center groups-list-table-sorter' style={{ width: width }}>
      <span
        style={{
          color: name == sort?.name && '#6123FF',
          marginRight: '6px',
          lineHeight: '16px'
        }}
      >
        {name}
      </span>
      {sort?.name == name && (
        <svg
          style={{
            transform: !sort.asc && 'rotate(180deg)',
            transition: '.3s'
          }}
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7.83325 3.33594V12.8359'
            stroke='#6123FF'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M3.33325 8.33594L7.83325 12.8359L12.3333 8.33594'
            stroke='#6123FF'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    </div>
  )
}

const Workflows = () => {
  const { items, loading, handleUpdate, lastLog } = useWorkflows()
  const [uncheckedTabs, setUncheckedtabs] = useState([])
  const [itemsFiltred, setItemsFiltred] = useState([])
  const [openShowPackageDrawer, setOpenShowPackageDrawer] = useState(false) ///
  const [openViewPackageDrawer, setOpenViewPackageDrawer] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const [selectedId, setSelectedId] = useState(null)

  const [search, setSearch] = useState('')
  const debouncedSearchTerm = useDebounce(search, 500)

  const navigate = useNavigate()

  const onFilter = criteria => {
    if (criteria?.searchText) {
      setItemsFiltred(
        items
          .filter(el => el.name.toLowerCase().includes(criteria.searchText.toLowerCase()))
          .filter(el => !uncheckedTabs.map(el => el.toUpperCase()).includes(el.status))
      )
    } else {
      setItemsFiltred(items.filter(el => !uncheckedTabs.map(el => el.toUpperCase()).includes(el.status)))
    }
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      onFilter({ searchText: search })
    } else {
      onFilter({ searchText: '' })
    }
  }, [debouncedSearchTerm])

  const { setSuccessMessage } = useContext(SnackBarContext)

  const handleSelectItem = id => {
    setSelectedId(id)
    setOpenViewPackageDrawer(false)
    setOpenShowPackageDrawer(true)
    setIsEdit(true)
  }

  const handleDeleteItem = id => {
    setSelectedId(id)
    setShowDeleteDialog(true)
  }

  const handleViewPackage = id => {
    setSelectedId(id)
    setOpenViewPackageDrawer(true)
  }

  const deleteWorkflow = () => {
    workflowsService
      .deleteWorkflow(selectedId)
      .then(() => {
        setShowDeleteDialog(false)
        setSuccessMessage('Workflow deleted successfully')
        setSelectedId(null)
        handleUpdate()
      })
      .catch(err => {
        console.error(err)
        setSelectedId(null)
      })
  }

  useEffect(() => {
    onFilter()
  }, [items])

  const statuses = [
    {
      id: 1,
      text: 'Running',
      itemsCount: items.filter(el => el.status == 'RUNNING').length || 0
    },
    {
      id: 2,
      text: 'Paused',
      itemsCount: items.filter(el => el.status == 'PAUSED').length || 0
    }
  ]

  useEffect(() => {
    setItemsFiltred(
      items
        .filter(el => el.trigger.name.toLowerCase().includes(search.toLowerCase()))
        .filter(el => !uncheckedTabs.map(el => el.toUpperCase()).includes(el.status))
    )
  }, [uncheckedTabs])

  const handleStatusSelect = name => {
    setUncheckedtabs(uncheckedTabs.includes(name) ? uncheckedTabs.filter(el => el !== name) : [...uncheckedTabs, name])
  }

  return (
    <div
      style={{
        background: '#F8F8FB',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh'
      }}
    >
      <div style={{ marginBottom: '32px', padding: '24px 24px 0px 24px' }} className='row row-center'>
        <h1 style={{ marginBottom: '0', fontSize: 24 }}>Workflows</h1>
        <StatusFilter
          uncheckedTabs={uncheckedTabs}
          statuses={statuses}
          onChange={handleStatusSelect}
          setUncheckedtabs={setUncheckedtabs}
        />
        <Spacer />

        <TextField
          sx={{
            width: '288px',
            marginRight: '24px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              border: '1px solid #E7E7EE',
              background: '#fff',
              width: '288px'
            }
          }}
          InputProps={{
            endAdornment: <SearchIcon />
          }}
          onChange={e => setSearch(e.target.value)}
          value={search}
          size='small'
          placeholder='Search Workflow'
        />

        <Button
          variant={'contained'}
          sx={{
            background: '#6123FF',
            borderRadius: '12px',
            fontFamily: 'Plus Jakarta Sans',
            color: '#fff',
            textTransform: 'none',
            width: 270
          }}
          startIcon={<PlusIcon color={'#fff'} />}
          onClick={() => {
            setOpenShowPackageDrawer(true)
          }}
        >
          New Workflow
        </Button>
      </div>

      {lastLog && (
        <div
          className='row'
          style={{ padding: '19px 24px', background: '#E3F5F7', margin: '0px 26px', borderRadius: 12 }}
        >
          <div className='column' style={{ marginRight: 48 }}>
            <div style={{ background: '#fff', borderRadius: 6, padding: '8px 21px' }}>
              <p style={{ fontWeight: 600, textTransform: 'uppercase', fontSize: 13 }}>Last log</p>
            </div>
          </div>
          <div className='column' style={{ marginRight: 48 }}>
            <p style={{ fontWeight: 600, fontSize: 14 }}>{dayjs.utc(lastLog?.actionAt).local().format('HH:mm')}</p>
            <p style={{ fontSize: 13 }}>{dayjs.utc(lastLog?.actionAt).local().format('DD MMMM')}</p>
          </div>
          <div className='column' style={{ marginRight: 48 }}>
            <p style={{ fontWeight: 600, fontSize: 14 }}>
              {lastLog?.userFirstName} {lastLog?.userLastName}
            </p>
            <p style={{ fontSize: 13 }}>{lastLog?.userEmail}</p>
          </div>
          <div className='column' style={{ marginRight: 48 }}>
            <p style={{ fontWeight: 600, fontSize: 14 }}>Trigger: {lastLog?.workflowTriggerName}</p>
            <p style={{ fontSize: 13, color: '#6123FF' }}>{lastLog?.workflowName}</p>
          </div>
          <Spacer />
          <div
            className='row row-center'
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/workflows-total-logs')}
          >
            <p style={{ color: '#6123FF', marginRight: 6 }}>Show total historical logs</p>
            <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1.33398 6.16675L10.834 6.16675'
                stroke='#6123FF'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M6.33398 10.6667L10.834 6.16675L6.33398 1.66675'
                stroke='#6123FF'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div></div>
        </div>
      )}

      <div
        className='wfs-content'
        style={{
          padding: '20px',
          flex: 1,
          overflow: 'auto'
        }}
      >
        {loading && <LoadingIndicator />}
        {items.length == 0 && (
          <div style={{ alignItems: 'center', marginTop: '100px' }} className='column'>
            <div
              style={{ position: 'relative', width: 785, height: 457, justifyContent: 'center', margin: '-100px 0px' }}
              className='column column-center'
            >
              <img
                style={{ position: 'absolute', top: 0, left: 0, width: 785 }}
                src={'images/wf-empty-bg.png'}
                alt=''
              />
              <img src={'images/GitFork.png'} width={180} alt='' />
            </div>
            <p style={{ color: '#1B1B26', fontWeight: 700, fontSize: '16px', textAlign: 'center', marginBottom: 8 }}>
              Create and Manage Workflows
            </p>
            <p style={{ textAlign: 'center', maxWidth: 659, lineHeight: '20px', marginBottom: 24 }}>
              This is your hub for creating and managing automated workflows. Here, you can set up triggers, define
              actions, and assign users to streamline your processes. This screen ensures you have full control over
              your organization's automated responses to various events and tasks
            </p>
            <Button
              variant='outlined'
              onClick={() => {
                setOpenShowPackageDrawer(true)
              }}
              sx={{
                padding: '7px 40px',
                borderColor: '#E7E7EE',
                borderRadius: '12px',
                color: '#6123FF',
                textTransform: 'none',
                flex: '1'
              }}
              startIcon={<PlusIcon color={'#6123FF'} />}
            >
              New Workflow
            </Button>
          </div>
        )}
        {(search || uncheckedTabs.length !== 0) && itemsFiltred.length == 0 && (
          <div style={{ alignItems: 'center', marginTop: '100px' }} className='column'>
            <div
              style={{ position: 'relative', width: 785, height: 457, justifyContent: 'center', margin: '-100px 0px' }}
              className='column column-center'
            >
              <img
                style={{ position: 'absolute', top: 0, left: 0, width: 785 }}
                src={'images/wf-empty-bg.png'}
                alt=''
              />
              <img src={'images/GitFork.png'} width={180} alt='' />
            </div>
            <p style={{ color: '#76767F', textAlign: 'center' }}>
              The search did not return any results,
              <br /> try another query
            </p>
          </div>
        )}

        {itemsFiltred &&
          itemsFiltred.length !== 0 &&
          itemsFiltred.map(item => (
            <WorkflowsListItem
              search={search}
              key={item.id}
              item={item}
              onDelete={handleDeleteItem}
              onEdit={handleSelectItem}
              onView={handleViewPackage}
              handleUpdate={handleUpdate}
            />
          ))}
      </div>

      {/* <CustomizedDialogs
        title={'Add Workflow'}
        open={openShowPackageDrawer}
      >
        <WorkflowCreate
          handleClose={event => {
            setOpenShowPackageDrawer(false)
            setIsEdit(false)
            handleUpdate()
            if (event) {
              setSuccessMessage('Workflow ' + event)
            }
          }}
          selectedId={selectedId}
          isEdit={isEdit}
        />

      </CustomizedDialogs> */}

      <Drawer
        sx={{ '& .MuiDrawer-paper': { background: '#F8F8FB' } }}
        anchor='right'
        open={openShowPackageDrawer}
        onClose={() => {
          setOpenShowPackageDrawer(false)
          setIsEdit(false)
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: '24px',
            right: '982px',
            cursor: 'pointer'
          }}
          onClick={() => {
            setOpenShowPackageDrawer(false)
            setIsEdit(false)
          }}
        >
          <CloseDrawer />
        </div>
        <WorkflowCreate
          handleClose={event => {
            setOpenShowPackageDrawer(false)
            setIsEdit(false)
            handleUpdate()
            if (event) {
              setSuccessMessage('Workflow ' + event)
            }
          }}
          selectedId={selectedId}
          isEdit={isEdit}
        />
      </Drawer>

      <Drawer
        anchor='right'
        open={openViewPackageDrawer}
        onClose={() => {
          setOpenViewPackageDrawer(false)
          handleUpdate()
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: '24px',
            right: '1202px',
            cursor: 'pointer'
          }}
          onClick={() => {
            setOpenViewPackageDrawer(false)
            handleUpdate()
          }}
        >
          <CloseDrawer />
        </div>
        <ViewWorkflow onEdit={handleSelectItem} id={selectedId} />
      </Drawer>
      <DeleteDialog
        onClose={() => setShowDeleteDialog(false)}
        onAction={deleteWorkflow}
        str={' ' + items.find(el => el?.id == selectedId)?.name}
        open={showDeleteDialog}
      ></DeleteDialog>
    </div>
  )
}

export default Workflows
