import React from 'react'
import Info from 'src/common/components/Info'
import Spacer from 'src/common/components/Spacer'

export const TrainingPackageStatsBlock = ({ stats }) => {
  return (
    <div className='row'>
      <div
        style={{
          padding: '16px 24px',
          borderRadius: '10px',
          border: '1px solid #E7E7EE',
          marginBottom: '16px',
          background: '#fff',
          width: '30%',
          position: 'relative'
        }}
        className='column'
      >
        <div
          style={{
            marginBottom: '10px'
          }}
          className='row row-center'
        >
          <p
            style={{
              fontWeight: 600,
              color: '#76767F'
            }}
          >
            Enrolled users
          </p>
          <Info text={'Who have active training packages'} />
        </div>

        <div className='row row-center'>
          <p
            style={{
              fontFamily: 'Satoshi',
              fontSize: '42px',
              marginRight: '16px'
            }}
          >
            {stats?.enrolledUsersCount}
          </p>

          <div className='progress-hatched row'>
            <div
              className='progress-hatched-inner row row-center'
              style={{
                width: stats?.enrolledUsersPercentage + '%',
                minWidth: '87px'
              }}
            >
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  opacity='0.2'
                  d='M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z'
                  fill='#1B1B26'
                />
                <path
                  d='M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z'
                  stroke='#1B1B26'
                  strokeMiterlimit='10'
                />
                <path
                  d='M14.5703 5.43271C15.2408 5.24379 15.9441 5.20075 16.6326 5.3065C17.3212 5.41224 17.9791 5.66431 18.562 6.04572C19.1449 6.42714 19.6393 6.92904 20.012 7.51763C20.3846 8.10622 20.6268 8.76783 20.7221 9.45789C20.8175 10.148 20.764 10.8504 20.565 11.5181C20.366 12.1857 20.0263 12.8029 19.5687 13.3281C19.1111 13.8534 18.5463 14.2745 17.9123 14.5631C17.2782 14.8517 16.5897 15.001 15.8931 15.0011'
                  stroke='#1B1B26'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M1.5 18.5059C2.26138 17.4229 3.27215 16.539 4.44698 15.9288C5.62182 15.3186 6.92623 15.0001 8.25008 15C9.57393 14.9999 10.8784 15.3184 12.0532 15.9285C13.2281 16.5386 14.239 17.4225 15.0004 18.5054'
                  stroke='#1B1B26'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M15.8945 15C17.2185 14.999 18.5232 15.3171 19.6982 15.9273C20.8732 16.5375 21.8838 17.4218 22.6446 18.5054'
                  stroke='#1B1B26'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '600'
                }}
              >
                {' '}
                {stats?.enrolledUsersPercentage} %
              </p>
            </div>
          </div>
        </div>
      </div>

      <Spacer />
      <div
        style={{
          padding: '16px 24px',
          borderRadius: '10px',
          border: '1px solid #E7E7EE',
          marginBottom: '16px',
          background: '#fff',
          width: 'calc(70% - 20px)',
          position: 'relative'
        }}
        className='column'
      >
        <div
          style={{
            marginBottom: '10px'
          }}
          className='row row-center'
        >
          <div className='column'>
            <p
              style={{
                fontWeight: 600,
                color: '#76767F',
                marginRight: '50px',
                marginBottom: '12px'
              }}
            >
              Training packages
            </p>

            <div className='row' style={{ alignItems: 'flex-end' }}>
              <p
                style={{
                  color: '#AD39F4',
                  fontSize: '42px',
                  fontFamily: 'Satoshi',
                  marginRight: '10px'
                }}
              >
                {' '}
                {Math.round((stats?.missedCount / stats?.totalCount) * 100) || 0}%
              </p>
              <p style={{ fontSize: '14px', color: '#76767F' }}>Currently</p>
            </div>
          </div>
          <div
            style={{
              height: '100%',
              flex: '1',
              justifyContent: 'space-between'
            }}
            className='column'
          >
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  background: '#E7E7EE',
                  height: '8px',
                  flex: '1',
                  borderRadius: '8px',
                  overflow: 'hidden'
                }}
                className='row row-center'
              >
                <div
                  style={{
                    width: (Math.round((stats?.completedCount / stats?.totalCount) * 100) || 0) + '%',
                    background: '#59D399',
                    height: '12px',
                    borderRadius: '8px',
                    position: 'relative',
                    border: '3px solid #fff'
                  }}
                ></div>
                <div
                  style={{
                    width: (Math.round((stats?.missedCount / stats?.totalCount) * 100) || 0) + '%',
                    background: '#DC87F8',
                    height: '12px',
                    borderRadius: '8px',
                    position: 'relative',
                    border: '3px solid #fff',
                    marginLeft: '-8px'
                  }}
                ></div>
              </div>
            </div>
            <Spacer />

            <div className='row' style={{ alignItems: 'flex-end' }}>
              <svg
                style={{ marginRight: '8px' }}
                width='24'
                height='25'
                viewBox='0 0 24 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle opacity='0.1' cx='12' cy='12.5' r='12' fill='#76767F' />
                <path
                  opacity='0.2'
                  d='M12 14.75C13.2426 14.75 14.25 13.7426 14.25 12.5C14.25 11.2574 13.2426 10.25 12 10.25C10.7574 10.25 9.75 11.2574 9.75 12.5C9.75 13.7426 10.7574 14.75 12 14.75Z'
                  fill='#76767F'
                />
                <path
                  d='M12 18.6875C15.4173 18.6875 18.1875 15.9173 18.1875 12.5C18.1875 9.08274 15.4173 6.3125 12 6.3125C8.58274 6.3125 5.8125 9.08274 5.8125 12.5C5.8125 15.9173 8.58274 18.6875 12 18.6875Z'
                  stroke='#76767F'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path d='M12 4.90625V7.71875' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M4.40625 12.5H7.21875' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M12 20.0938V17.2812' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M19.5938 12.5H16.7812' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
                <path
                  d='M12 14.75C13.2426 14.75 14.25 13.7426 14.25 12.5C14.25 11.2574 13.2426 10.25 12 10.25C10.7574 10.25 9.75 11.2574 9.75 12.5C9.75 13.7426 10.7574 14.75 12 14.75Z'
                  stroke='#76767F'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>

              <p
                style={{
                  fontSize: '24px',
                  fontFamily: 'Satoshi',
                  marginRight: '4px'
                }}
              >
                {Math.round((stats?.completedCount / stats?.totalCount) * 100) || 0}%
              </p>
              <p
                style={{
                  fontSize: '14px',
                  color: '#76767F',
                  marginRight: '28px'
                }}
              >
                Completed
              </p>
              <svg
                style={{ marginRight: '8px' }}
                width='24'
                height='25'
                viewBox='0 0 24 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle opacity='0.1' cx='12' cy='12.5' r='12' transform='rotate(180 12 12.5)' fill='#76767F' />
                <path
                  d='M16.5 8L8.25 16.25L7.875 16.625'
                  stroke='#76767F'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path d='M14.8125 17H7.5V9.6875' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
              </svg>

              <p
                style={{
                  fontSize: '24px',
                  fontFamily: 'Satoshi',
                  marginRight: '4px'
                }}
              >
                {Math.round((stats?.failedCount / stats?.totalCount) * 100) || 0}%
              </p>
              <p style={{ fontSize: '14px', color: '#76767F' }}>Dropped</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
