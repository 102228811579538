import { Slide, Snackbar } from '@mui/material'
import React from 'react'

function SlideTransition(props) {
  return <Slide {...props} direction='left' />
}

export default function SnackbarCustom({ successMessage, warningMessage, errorMessage, onClose }) {
  const color = successMessage ? '#303051F2' : warningMessage ? '#E3A202' : errorMessage ? '#B40020' : 'transparrent'

  return (
    <Snackbar
      open={!!successMessage || !!warningMessage || !!errorMessage}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      autoHideDuration={3000}
    >
      <div
        className='row'
        style={{
          backgroundColor: color,
          borderRadius: '8px',
          padding: '12px 16px'
        }}
      >
        {successMessage && (
          <svg
            style={{ marginRight: 8 }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M20.25 6.75L9.75 17.2495L4.5 12' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        )}
        {warningMessage && (
          <svg
            style={{ marginRight: 8 }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              opacity='0.2'
              d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
              fill='#ffffff'
            />
            <path
              d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
              stroke='#ffffff'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path d='M11.25 11.25H12V16.5H12.75' stroke='#ffffff' strokeLinecap='round' strokeLinejoin='round' />
            <path
              d='M12 9C12.6213 9 13.125 8.49632 13.125 7.875C13.125 7.25368 12.6213 6.75 12 6.75C11.3787 6.75 10.875 7.25368 10.875 7.875C10.875 8.49632 11.3787 9 12 9Z'
              fill='#ffffff'
            />
          </svg>
        )}
        {errorMessage && (
          <svg
            style={{ marginRight: 8 }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              opacity='0.2'
              d='M10.6996 3.74857L2.45179 17.9978C2.31982 18.2259 2.25022 18.4846 2.25 18.748C2.24978 19.0115 2.31895 19.2703 2.45055 19.4986C2.58215 19.7268 2.77153 19.9163 2.99964 20.0481C3.22776 20.1799 3.48656 20.2493 3.75 20.2493H20.2456C20.509 20.2493 20.7678 20.1799 20.9959 20.0481C21.224 19.9163 21.4134 19.7268 21.545 19.4986C21.6766 19.2703 21.7458 19.0115 21.7456 18.748C21.7453 18.4846 21.6757 18.2258 21.5438 17.9978L13.296 3.74857C13.1642 3.52093 12.9749 3.33193 12.7471 3.20055C12.5192 3.06916 12.2608 3 11.9978 3C11.7348 3 11.4764 3.06916 11.2485 3.20055C11.0206 3.33193 10.8313 3.52093 10.6996 3.74857Z'
              fill='#ffffff'
            />
            <path d='M12 10.5V13.5' stroke='#ffffff' strokeLinecap='round' strokeLinejoin='round' />
            <path
              d='M10.6996 3.74857L2.45179 17.9978C2.31982 18.2258 2.25022 18.4846 2.25 18.748C2.24978 19.0115 2.31895 19.2703 2.45055 19.4986C2.58215 19.7268 2.77153 19.9163 2.99964 20.0481C3.22776 20.1799 3.48656 20.2493 3.75 20.2493H20.2455C20.509 20.2493 20.7678 20.1799 20.9959 20.0481C21.224 19.9163 21.4134 19.7268 21.545 19.4986C21.6766 19.2703 21.7458 19.0115 21.7456 18.748C21.7453 18.4846 21.6757 18.2258 21.5438 17.9978L13.296 3.74857C13.1642 3.52093 12.9749 3.33193 12.7471 3.20055C12.5192 3.06916 12.2608 3 11.9978 3C11.7348 3 11.4764 3.06916 11.2485 3.20055C11.0206 3.33193 10.8313 3.52093 10.6996 3.74857V3.74857Z'
              stroke='#ffffff'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z'
              fill='#ffffff'
            />
          </svg>
        )}

        <span
          style={{
            color: '#fff',
            fontWeight: '500',
            fontFamily: 'Plus Jakarta Sans'
          }}
        >
          {successMessage || warningMessage || errorMessage}
        </span>
      </div>
    </Snackbar>
  )
}
