import React, { useEffect, useState } from 'react'
import DynamicStepper from './DynamicStepper'
import { Box, Grid } from '@mui/material'
import StepCard from './StepCard'
import CampaignTimelines from './CampaignTimelines'
import CampaignTemplates from './CampaignTemplates'
import EditIcon from '../../../../common/icons/editIcon'
import ActionButtons from '../../ActionButtons'
import DeleteIcon from '../../../../common/icons/deleteIcon'
import StepFormModal from '../StepFormModal'
import { useDeleteStepSimulationMutation } from '../../../../api/simulation/simulation'
import { useParams } from 'react-router-dom'
import customToastWithAlert from '../../../../common/components/CustomToastWithAlert'
import DeleteDialog from '../../../../common/components/DeleteDialog'

export default function OverviewList({ data }) {
  const { id } = useParams()
  const [isEditShowing, setIsEditShowing] = useState(false)
  const [isDeleteShowing, setIsDeleteShowing] = useState(false)
  const [editData, setEditData] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [handleDelete, { isSuccess }] = useDeleteStepSimulationMutation()

  useEffect(() => {
    if (isSuccess) {
      customToastWithAlert({
        message: 'Step deleted successfully'
      })
    }
  }, [isSuccess])

  function handleEdit(data) {
    setEditData(data)
    setIsEditShowing(true)
  }
  function handleDeleteStep() {
    handleDelete({
      simulationId: id,
      stepId: selectedItem.id
    })
    setIsDeleteShowing(false)
  }
  function setShowDeleteDialog(row) {
    setIsDeleteShowing(true)
    setSelectedItem(row)
  }
  function handleClose() {
    setIsEditShowing(false)
    setEditData(null)
  }
  const sortedSteps = sortSequenceNumber(data?.steps)
  return (
    <Grid
      container
      width={'100%'}
      sx={{
        paddingTop: '16px',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 340px)',
        overflowY: 'auto',
        overflowX: 'auto',
        '::-webkit-scrollbar': {
          width: '6px',
          height: '6px'
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#DBDBE6',
          borderRadius: '2px',
          '&:hover': {
            backgroundColor: '#DBDBE6'
          }
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: 'transparent'
        },
        scrollbarWidth: 'thin',
        scrollbarColor: '#DBDBE6 transparent'
      }}
    >
      <DynamicStepper status={data?.status} steps={sortedSteps} />
      <Box width={'calc(100% - 35px)'} display={'flex'} flexDirection={'column'} rowGap={'12px'}>
        {sortedSteps.map(step => (
          <Box key={step.title} width={'100%'}>
            <StepCard data={step} config={config(handleEdit, setShowDeleteDialog, data?.status)} />
          </Box>
        ))}
      </Box>
      {isEditShowing && (
        <StepFormModal
          open={isEditShowing}
          handleClose={handleClose}
          step={editData?.sequenceNumber}
          rowData={editData}
        />
      )}
      {isDeleteShowing && (
        <DeleteDialog
          str={`step number ${selectedItem?.sequenceNumber}`}
          open={isDeleteShowing}
          onAction={handleDeleteStep}
          onClose={() => setIsDeleteShowing(false)}
        />
      )}
    </Grid>
  )
}
const config = (handleEdit, setShowDeleteDialog, status) => [
  {
    field: 'steps',
    title: '',
    renderComponent: (row, data) => <CampaignTimelines data={data} />
  },
  {
    field: 'templates',
    title: '',
    width: '20%',
    minWidth: 250,
    renderComponent: (row, data) => <CampaignTemplates data={data} />
  },
  {
    field: 'usersCount',
    title: 'Users',
    width: '10%'
  },
  {
    field: 'simulationsSentCount',
    title: 'Sent',
    width: '10%'
  },

  {
    field: 'simulationsOpenedCount',
    title: 'Opened',
    width: '10%'
  },
  {
    field: 'simulationsPhishedCount',
    title: 'Phished',
    width: '10%'
  },
  {
    field: 'simulationsReportedCount',
    title: 'Reported',
    width: '10%'
  },
  {
    field: 'actions',
    headerName: '',
    stickyRight: true,
    width: '10%',
    minWidth: 46,
    renderComponent: (value, row) => (
      <ActionButtons
        config={[
          {
            name: 'Edit',
            icon: <EditIcon color={'#76767F'} />,
            onClick: () => handleEdit(row)
          },
          {
            name: 'Delete',
            icon: <DeleteIcon color={'#76767F'} />,
            onClick: () => setShowDeleteDialog(row),
            isHidden: row.sequenceNumber === 1
          }
        ]}
        isHidden={status !== 'IN_PROGRESS'}
      />
    )
  }
]

function sortSequenceNumber(data) {
  return [...data]?.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
}
