import React from 'react'

const BackIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M7.5 12.75L3 8.25L7.5 3.75' stroke='#6123FF' strokeLinecap='round' />
    <path
      d='M7.5 18.75H15.75C17.1424 18.75 18.4777 18.1969 19.4623 17.2123C20.4469 16.2277 21 14.8924 21 13.5V13.5C21 12.8106 20.8642 12.1279 20.6004 11.4909C20.3365 10.8539 19.9498 10.2752 19.4623 9.78769C18.9748 9.30018 18.3961 8.91347 17.7591 8.64963C17.1221 8.38579 16.4394 8.25 15.75 8.25H3'
      stroke='#6123FF'
      strokeLinecap='round'
    />
  </svg>
)

export default BackIcon
