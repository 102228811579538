import { Button, Menu, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomCheckbox from '../CustomCheckbox'
import { SortHeader } from 'src/views/PackageCreate'
import Spacer from '../Spacer'
import groupsService from 'src/services/groups.service'
import userService from 'src/services/user.service'
import UserAvatar from '../UserAvatar'
import useDebounce from 'src/hooks'
import Highlighter from 'react-highlight-words'

const MODES = {
  groups: 'groups',
  users: 'users'
}

export const TableBigNum = ({ color, num }) => (
  <span
    style={{
      fontFamily: 'Satoshi',
      fontSize: '24px',
      fontWeight: '500',
      color: color ? color : num < 50 && '#DB0F34'
    }}
  >
    {num ? num : '0'}
  </span>
)

const GroupsListItem = ({ item, onSelect, selected, search }) => {
  return (
    <div key={item.name} className='groups-list-item row row-center'>
      <CustomCheckbox checked={selected} onClick={() => onSelect(item.id)} />
      <div className='row' style={{ width: '75%', alignItems: 'flex-start' }}>
        <span style={{ fontWeight: 600, maxWidth: '540px' }} className='text-shrink-1'>
          <Highlighter
            highlightClassName='highlight-text'
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={item.name}
          />
        </span>
      </div>
      <div style={{ width: '25%' }}>
        <TableBigNum color={'#1B1B26'} num={item.usersCount} />
      </div>
    </div>
  )
}
const UsersListItem = ({ item, onSelect, selected, search }) => {
  return (
    <div key={item.name} className='groups-list-item row row-center'>
      <CustomCheckbox checked={selected} onClick={() => onSelect(item.id)} />
      <div className='row row-center' style={{ width: '55%' }}>
        <UserAvatar firstName={item?.firstName} lastName={item?.lastName} />
        <span style={{ fontWeight: 600, maxWidth: '540px' }} className='text-shrink-1'>
          <Highlighter
            highlightClassName='highlight-text'
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={`${item?.firstName || 'unknown'} ${item?.lastName || 'unknown'}`}
          />
        </span>
      </div>
      <div style={{ width: '45%' }}>{item.email}</div>
    </div>
  )
}
const GroupsList = ({
  groups,
  handleSelectAllGroups,
  selectedGroups,
  onSelectGroup,
  sortBy,
  setSortBy,
  setSearch,
  search
}) => {
  const [sortedItems, setSortedItems] = useState(groups)

  const selectedAll = !groups.map(el => selectedGroups.includes(el.id)).includes(false)

  const onSelectAll = () => {
    handleSelectAllGroups(selectedAll)
  }

  useEffect(() => {
    setSortedItems(groups)
  }, [groups])

  const handleSort = field => {
    const fieldset = {
      name: 'name',
      users: 'usersCount'
    }
    const fieldName = fieldset[field.toLowerCase()]

    setSortedItems(
      groups.sort((a, b) => {
        if (a[fieldName] < b[fieldName]) {
          return sortBy?.asc ? -1 : 1
        }
        if (a[fieldName] > b[fieldName]) {
          return sortBy?.asc ? 1 : -1
        }
        return 0
      })
    )

    setSortBy({
      name: field,
      asc: field == sortBy?.name ? !sortBy.asc : true
    })
  }
  return (
    <div
      style={{
        maxHeight: '500px',
        overflow: 'auto'
      }}
    >
      <div>
        <TextField
          fullWidth
          size='small'
          placeholder='Search'
          onChange={e => setSearch(e.target.value)}
          value={search}
          variant='outlined'
        />
        <div className='row row-center groups-list-heading'>
          <CustomCheckbox checked={selectedAll} onClick={onSelectAll} />
          <SortHeader name='Name' sort={sortBy} onSort={handleSort} width={'75%'} />
          <SortHeader name='Users' sort={sortBy} onSort={handleSort} width={'25%'} />
        </div>
        {sortedItems.map(item => (
          <GroupsListItem
            search={search}
            selected={selectedGroups?.includes(item.id)}
            onSelect={onSelectGroup}
            key={item.id}
            item={item}
          />
        ))}
      </div>
    </div>
  )
}

const UsersList = ({
  users,
  handleSelectAllUsers,
  selectedUsers,
  onSelectUser,
  sortBy,
  setSortBy,
  setSearch,
  search
}) => {
  const [sortedItems, setSortedItems] = useState(users)

  const selectedAll = !users.map(el => selectedUsers.includes(el.id)).includes(false)

  const onSelectAll = () => {
    handleSelectAllUsers(selectedAll)
  }

  useEffect(() => {
    setSortedItems(users)
  }, [users])

  const handleSort = field => {
    const fieldset = {
      name: 'name',
      email: 'email'
    }
    const fieldName = fieldset[field.toLowerCase()]

    setSortedItems(
      users
        .map(el => ({
          ...el,
          name: (el.firstName || 'unknown') + (el.lastName || 'unknown')
        }))
        .sort((a, b) => {
          if (a[fieldName] < b[fieldName]) {
            return sortBy?.asc ? -1 : 1
          }
          if (a[fieldName] > b[fieldName]) {
            return sortBy?.asc ? 1 : -1
          }
          return 0
        })
    )

    setSortBy({
      name: field,
      asc: field == sortBy?.name ? !sortBy.asc : true
    })
  }

  return (
    <div
      style={{
        maxHeight: '500px',
        overflow: 'auto'
      }}
    >
      <div>
        <TextField
          fullWidth
          size='small'
          placeholder='Search'
          onChange={e => setSearch(e.target.value)}
          value={search}
          variant='outlined'
        />
        <div className='row row-center groups-list-heading'>
          <CustomCheckbox checked={selectedAll} onClick={onSelectAll} />
          <SortHeader name='Name' sort={sortBy} onSort={handleSort} width={'55%'} />
          <SortHeader name='Email' sort={sortBy} onSort={handleSort} width={'45%'} />
        </div>
        {sortedItems.map(item => (
          <UsersListItem
            selected={selectedUsers?.includes(item.id)}
            onSelect={onSelectUser}
            key={item.id}
            item={item}
            search={search}
          />
        ))}
      </div>
    </div>
  )
}

const defaultFilters = {
  page: '0',
  size: 10,
  statuses: 'ACTIVE'
}

export function ChooseUsersGroups({ selectedUsers = [], selectedGroups = [], onSelectUser, onSelectGroup }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [loading, setLoading] = useState(null)
  const [mode, setMode] = useState(MODES.groups)
  const [allGroupsSelected, setAllGroupsSelected] = useState(false)
  const [allUsersSelected, setAllUsersSelected] = useState(false)

  const [allGroups, setAllGroups] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  // const [totalUsersPages, setTotalPages] = useState(1)
  const [page, setPage] = useState(1)
  const open = Boolean(anchorEl)

  const handlePageChange = pageNum => {
    setPage(pageNum)
    handleFilter({ page: (pageNum - 1).toString() })
  }

  const [sortBy, setSortBy] = useState(null)

  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState(defaultFilters)

  const debouncedSearchTerm = useDebounce(search, 500)

  const handleFilter = param => {
    setFilters({ ...filters, ...param })
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      // setIsSearching(true);
      handleFilter({ searchText: search })
    } else {
      handleFilter({ searchText: '' })
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    setLoading(true)
    if (mode == MODES.groups) {
      groupsService.getGroups(filters).then(groupsData => {
        setLoading(false)
        setAllGroups(groupsData.data)
        setTotalPages(Math.ceil(parseInt(groupsData.headers['x-total-count']) / defaultFilters.size))
      })
    } else {
      userService.getUsers(filters).then(resp => {
        setLoading(false)
        setAllUsers(resp.data)
        setTotalPages(Math.ceil(parseInt(resp.headers['x-total-count']) / defaultFilters.size))
      })
    }
  }, [filters, mode])

  const openUsersGroupMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelectAllGroups = selectedAll => {
    // setAllGroupsSelected(!allGroupsSelected)
    // const newItems = selectedAll ?
    onSelectGroup(
      allGroups.map(el => el.id),
      selectedAll
    )
  }
  const handleSelectAllUsers = selectedAll => {
    // setAllUsersSelected(!allUsersSelected)
    onSelectUser(
      allUsers.map(el => el.id),
      selectedAll
    )
  }

  useEffect(() => {
    setLoading(true)
    Promise.all([
      groupsService.getGroups(filters)
      // userService.getUsers(filters),
    ])
      .then(values => {
        const [groupsData] = values
        setLoading(false)
        setAllGroups(groupsData.data)
        setTotalPages(Math.ceil(parseInt(groupsData.headers['x-total-count']) / defaultFilters.size))
        // setAllUsers(usersData.data)
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }, [])

  return (
    <>
      <p className='drawer-subtitle'>
        Choose users
        <span
          onClick={openUsersGroupMenu}
          className='users-groups-switcher'
          style={{
            color: '#6123FF',
            cursor: 'pointer',
            marginLeft: 10,
            fontWeight: 700
          }}
        >
          by {mode == MODES.groups ? 'Groups' : 'Users'}
          <svg
            style={{
              margin: '0 10px',
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: '300ms'
            }}
            width='18'
            height='10'
            viewBox='0 0 18 10'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1.5 1L9 8.5L16.5 1'
              stroke='#6123FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </span>
        <Menu
          sx={{
            '& .MuiPaper-root': {
              color: '#1B1B26',
              borderRadius: '12px'
            },
            '& li': {
              opacity: '0.7',
              fontFamily: 'Plus Jakarta Sans'
            },
            '& li:hover': {
              opacity: '1'
            }
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              setMode(MODES.groups)
              handlePageChange(1)
              setSearch('')
            }}
          >
            Groups
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              setMode(MODES.users)
              handlePageChange(1)
              setSearch('')
            }}
          >
            Users
          </MenuItem>
        </Menu>
      </p>

      {mode == MODES.groups ? (
        <GroupsList
          groups={allGroups}
          allGroupsSelected={allGroupsSelected}
          handleSelectAllGroups={handleSelectAllGroups}
          selectedGroups={selectedGroups}
          onSelectGroup={onSelectGroup}
          sortBy={sortBy}
          setSortBy={setSortBy}
          setSearch={setSearch}
          search={search}
        />
      ) : (
        <UsersList
          users={allUsers}
          setAllUsersSelected={allUsersSelected}
          handleSelectAllUsers={handleSelectAllUsers}
          selectedUsers={selectedUsers}
          onSelectUser={onSelectUser}
          sortBy={sortBy}
          setSortBy={setSortBy}
          setSearch={setSearch}
          search={search}
        />
      )}
      <div className='row row-center'>
        <p>
          Page <strong>{page}</strong> of <strong>{totalPages}</strong>
        </p>
        <Spacer />
        <Button
          onClick={() => handlePageChange(page - 1)}
          disabled={page == 1}
          variant='outlined'
          sx={{
            border: '1px solid #D0D0D8',
            color: '#1B1B26',
            textTransform: 'unset',
            mt: '18px',
            mb: '24px',
            mr: '24px',
            borderRadius: '12px',
            width: '148px'
          }}
        >
          Previous
        </Button>
        <Button
          onClick={() => handlePageChange(page + 1)}
          variant='outlined'
          disabled={page == totalPages || !totalPages}
          sx={{
            border: '1px solid #D0D0D8',
            color: '#1B1B26',
            textTransform: 'unset',
            mt: '18px',
            mb: '24px',
            borderRadius: '12px',
            width: '148px'
          }}
        >
          Next
        </Button>
      </div>
    </>
  )
}
