import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import Spacer from 'src/common/components/Spacer'
import EditIcon from 'src/common/icons/editIcon'
import HistoricalLogs from './histLogs'
import UsersTab from './users'
import CyberSwitch from 'src/common/components/Switch'
import workflowsService from 'src/services/workflows.service'
import { getPercent } from 'src/heplers'

const ViewWorkflow = ({ id, onEdit = () => {} }) => {
  const [activeTab, setActiveTab] = useState(1)
  const [loading, setLoading] = useState(false)
  const [workflow, setWorkflow] = useState(null)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setLoading(true)
    workflowsService.getWorkflow(id).then(resp => {
      setLoading(false)
      setWorkflow(resp.data)
    })
  }, [])

  const handleTabChange = name => {
    setActiveTab(tabs.find(el => el.text == name).id)
  }

  const handleStatusUpdate = () => {
    setDisabled(true)
    workflowsService
      .updateStatus(id, workflow.status == 'RUNNING' ? 'PAUSED' : 'RUNNING')
      .then(resp => {
        setWorkflow(resp.data)
        setDisabled(false)
      })
      .catch(err => {
        console.error(err)
        setDisabled(false)
      })
  }

  const tabs = [
    { id: 1, text: 'Historical logs' },
    { id: 2, text: 'Users ' }
  ]
  return (
    <>
      <div style={{ width: '1200px' }} className='row'>
        <div style={{ width: '400px', height: '100vh', padding: '32px' }} className='column'>
          <div style={{ marginBottom: 16 }} className='row row-center'>
            {/* <div className='row row-center'>
              <img src={'images/wf1.png'} width={28} alt="" />
              <p style={{ fontSize: 13, color: '#76767F', marginLeft: 12 }}>Microsoft Intune</p>
            </div> */}

            <div className='row row-center'>
              {workflow?.trigger.service == 'CYBERLIFT' && <img src={'images/wf3.png'} width={28} alt='' />}
              {workflow?.trigger.service == 'HAVE_I_BEEN_PWNED' && <img src={'images/wf4.png'} width={28} alt='' />}
              {workflow?.trigger.service == 'GMAIL' && <img src={'images/wf5.png'} width={28} alt='' />}
              {workflow?.trigger.service == 'OUTLOOK' && <img src={'images/wf6.png'} width={28} alt='' />}
              <p
                style={{
                  fontSize: 13,
                  color: '#76767F',
                  marginLeft: 12,
                  textTransform: 'capitalize',
                  marginRight: 20
                }}
              >
                {workflow?.trigger && workflow?.trigger.name.replaceAll('_', ' ').toLowerCase()}
              </p>
            </div>

            <Spacer />
            <CyberSwitch disabled={disabled} checked={workflow?.status == 'RUNNING'} onChange={handleStatusUpdate} />
          </div>

          <h2 className='sato24-900'>{workflow?.name || ''}</h2>
          <div style={{ marginTop: 32 }} className='column'>
            <div className='row row-center'>
              <svg
                style={{ marginRight: 16 }}
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle cx='6' cy='6' r='5' stroke='#E7E7EE' strokeWidth='2' />
              </svg>
              <p style={{ color: '#76767F', fontWeight: 600 }}>Users</p>
            </div>

            <div className='row'>
              <div
                style={{
                  width: 2,
                  backgroundColor: '#E7E7EE',
                  height: '100%',
                  margin: '0px 21px 0px 5px'
                }}
              ></div>
              <div style={{ paddingBottom: 18 }}>
                <p className='sato42-500'>{workflow?.usersCount}</p>
              </div>
            </div>

            <div className='row row-center'>
              <svg
                style={{ marginRight: 16 }}
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle cx='6' cy='6' r='5' stroke='#E7E7EE' strokeWidth='2' />
              </svg>
              <p style={{ color: '#76767F', fontWeight: 600 }}> Triggered</p>
              <Spacer />
              <p style={{ color: '#76767F', fontWeight: 600 }}> {workflow?.triggeredCount} users</p>
            </div>
            <div className='row'>
              <div
                style={{
                  width: 2,
                  backgroundColor: '#E7E7EE',
                  height: '100%',
                  margin: '0px 21px 0px 5px'
                }}
              ></div>
              <div
                style={{
                  paddingBottom: 18,
                  paddingTop: 8,
                  flexGrow: 1
                }}
              >
                <div className='progress-hatched row'>
                  <div
                    className='progress-hatched-inner row row-center'
                    style={{
                      minWidth: '84px',
                      width: getPercent(workflow?.usersCount, workflow?.triggeredCount) + '%'
                    }}
                  >
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: '600'
                      }}
                    >
                      {getPercent(workflow?.usersCount, workflow?.triggeredCount)}%
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row row-center'>
              <svg
                style={{ marginRight: 16 }}
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle cx='6' cy='6' r='5' stroke='#E7E7EE' strokeWidth='2' />
              </svg>
              <p style={{ color: '#76767F', fontWeight: 600 }}>Actions applied</p>
            </div>

            <div className='row'>
              <div
                style={{
                  width: 2,
                  backgroundColor: '#E7E7EE',
                  height: '100%',
                  margin: '0px 21px 0px 5px'
                }}
              ></div>
              <div style={{ paddingBottom: 18 }}>
                <p className='sato42-500'>{workflow?.actions.length}</p>
              </div>
            </div>

            {/* <div className="row row-center">
              <svg style={{ marginRight: 16 }} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="5" stroke="#E7E7EE" strokeWidth="2" />
              </svg>
              <p style={{ color: '#76767F', fontWeight: 600 }}> Fixed behaviour</p>
              <Spacer />
              <p style={{ color: '#76767F', fontWeight: 600 }}> {0} users</p>

            </div> */}
            {/* <div className="row">
              <div style={{ width: 2, backgroundColor: '#fff', height: '100%', margin: '0px 21px 0px 5px' }}></div>
              <div style={{ paddingBottom: 18, paddingTop: 8, flexGrow: 1, }}>
                <div className="progress-hatched row">
                  <div className="progress-hatched-inner row row-center" style={{ minWidth: '84px', width: '0%', backgroundColor: '#D0F2BF' }}>
                    <p style={{
                      fontSize: '14px',
                      fontWeight: '600'
                    }}>0%</p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <Spacer />
          <div style={{ marginTop: '55px' }} className='row'>
            <Button
              variant='outlined'
              // disabled={item?.status == 'ARCHIVED'}
              sx={{
                borderColor: '#E7E7EE',
                borderRadius: '12px',
                color: '#6123FF',
                textTransform: 'none',
                flex: '1',
                mr: '12px'
              }}
              startIcon={<EditIcon />}
              onClick={() => onEdit(workflow?.id)}
            >
              Edit Workflow
            </Button>
          </div>
        </div>

        <div
          style={{
            width: '800px',
            background: '#F8F8FB',
            borderLeft: '1px solid #E7E7EE',
            padding: '32px'
          }}
          className='column'
        >
          <div
            style={{
              marginBottom: '36px'
            }}
            className='row tabs'
          >
            {tabs.map(el => (
              <div
                key={el.id}
                onClick={() => handleTabChange(el.text)}
                className={`tab ${activeTab == el.id && 'tab-active'}`}
              >
                <p>{el.text}</p>
              </div>
            ))}
          </div>

          {loading ? (
            <LoadingIndicator></LoadingIndicator>
          ) : (
            <>
              {activeTab == 1 && <HistoricalLogs id={id} />}
              {activeTab == 2 && <UsersTab id={id} />}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ViewWorkflow
