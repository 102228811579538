import React, { useContext, useEffect, useState } from 'react'
import { DashBoardSectionBlock, DashBoardSectionBlockTitle, Row } from '..'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
// import { Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom'
import statsService from 'src/services/stats.service'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import dayjs from 'dayjs'
import { getPercent } from 'src/heplers'
import { DashBoardDateContext } from 'src/App'
import { PeriodPicker } from '../components/periodPicker'

const renderTooltip = some => {
  if (some.payload) {
    return (
      <div
        style={{
          backgroundColor: '#303051F2',
          padding: '16px 20px',
          color: '#fff',
          borderRadius: '8px'
        }}
      >
        <div className='column'>
          <p style={{ fontWeight: 600, marginBottom: 6 }}>{some.payload[0]?.payload.name}</p>
          <div style={{ marginBottom: 6 }} className='row row-center'>
            <svg
              style={{ marginRight: 10 }}
              width='24'
              height='14'
              viewBox='0 0 24 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect width='24' height='14' fill='#B89CFF' />
              <rect width='24' height='14' fill='url(#paint0_linear_515_5943)' />
              <defs>
                <linearGradient
                  id='paint0_linear_515_5943'
                  x1='12'
                  y1='0'
                  x2='12'
                  y2='14'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stopColor='#0F88FF' />
                  <stop offset='1' stopColor='#14B0FF' />
                </linearGradient>
              </defs>
            </svg>
            <p style={{ marginRight: 6 }}>{some.payload[0]?.payload.followed}%</p>

            <p style={{ fontSize: '13px', opacity: 0.7 }}>MICRO-TRAINING</p>
          </div>
          <div className='row row-center'>
            <svg style={{ marginRight: 10, width: 24, height: 14 }}></svg>
            <p style={{ marginRight: 6 }}>{some.payload[0]?.payload.followedUsersCount}</p>

            <p style={{ fontSize: '13px', opacity: 0.7 }}>EMPLOYEE</p>
          </div>
        </div>
      </div>
    )
  }
}

export function PhishingMicroTrainingFollowed() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timePeriod, setTimePeriod] = useContext(DashBoardDateContext)

  const [loading, setloading] = useState(true)
  const [stats, setStats] = useState([])
  const [microtrainingStats, setMicrotrainingStats] = useState(null)
  const [employeeStats, setEmployeeStats] = useState(null)

  const getAllData = () => {
    const timePeriod3Month = {
      timePeriod: 'CUSTOM',
      startDate: dayjs(timePeriod.endDate).subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
      endDate: timePeriod.endDate
    }
    setloading(true)
    Promise.all([
      statsService.getCurrentCompanyMicroTrainingDailyStats(timePeriod3Month),
      statsService.getCurrentCompanyMicroTrainingStats(timePeriod),
      statsService.getCurrentCompanyStats(timePeriod)
    ]).then(values => {
      const [statsResp, microtrainingStatsResp, employeeStatsResp] = values
      setStats(statsResp.data)
      setloading(false)
      setMicrotrainingStats(microtrainingStatsResp.data)
      setEmployeeStats(employeeStatsResp.data)
    })
  }

  useEffect(() => {
    if (timePeriod) {
      getAllData()
    }
  }, [timePeriod])

  const data = stats
    .filter(el => !!el.followedUsersCount)
    .map(el => ({
      name: dayjs(el.day).format('DD MMM'),
      followed: el.completedPercentage,
      ...el
    }))

  const navigate = useNavigate()
  return (
    <div
      className='column'
      style={{
        backgroundColor: '#F8F8FB',
        height: '100vh',
        overflowY: 'auto',
        padding: '24px'
      }}
    >
      <div className='container-1030' style={{ minHeight: '100vh' }}>
        <div className='row row-center' onClick={() => navigate('/')} style={{ marginBottom: 14, cursor: 'pointer' }}>
          <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M16 8.25H1.75' stroke='#6123FF' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path
              d='M8.5 15L1.75 8.25L8.5 1.5'
              stroke='#6123FF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>

          <p style={{ color: '#6123FF', marginLeft: 8 }}>Back to Dashboard</p>
        </div>

        <div style={{ marginBottom: 24 }} className='row row-center'>
          <h1 style={{ margin: 0, fontSize: '36px' }}>Phishing Micro-training followed</h1>
          <PeriodPicker />
        </div>

        {loading ? (
          <LoadingIndicator />
        ) : (
          <DashBoardSectionBlock style={{ width: '100%', marginBottom: 16 }}>
            <Row>
              <div className='column' style={{ marginBottom: 48 }}>
                <DashBoardSectionBlockTitle>Micro-training</DashBoardSectionBlockTitle>
                <div className='row ' style={{ marginTop: 16 }}>
                  <p
                    className='sato-36'
                    style={{
                      marginRight: 10,
                      lineHeight: '36px'
                    }}
                  >
                    {getPercent(microtrainingStats?.totalCount, microtrainingStats?.completedCount)}%
                  </p>
                  <p className='secondary-text-14'>
                    {employeeStats?.totalCount} employees followed <br /> remedial micro-training
                  </p>
                </div>
              </div>
            </Row>
            <ResponsiveContainer width='100%' height={345}>
              <BarChart data={data}>
                <defs>
                  <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#0F88FF' stopOpacity={1} />
                    <stop offset='95%' stopColor='#14B0FF' stopOpacity={1} />
                  </linearGradient>
                </defs>
                <CartesianGrid vertical={false} strokeDasharray='3 3' />
                <XAxis dataKey='name' />
                <YAxis unit='%' orientation='right' />
                {/* <Tooltip /> */}
                <Tooltip cursor={false} content={renderTooltip} />
                <Bar style={{ borderRadius: 8 }} dataKey='followed' fill='url(#colorUv)' />
              </BarChart>
            </ResponsiveContainer>
          </DashBoardSectionBlock>
        )}
      </div>
    </div>
  )
}
