import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SIMULATIONS } from './tags'

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: window._env_.REACT_APP_API_HOST,
    prepareHeaders: headers => {
      const token = JSON.parse(localStorage.getItem('user')).accessToken || null
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('X-Custom-Header', 'value')
      return headers
    }
  }),
  refetchOnMountOrArgChange: 30,
  tagTypes: [SIMULATIONS.SIMULATION],
  endpoints: () => ({})
})
