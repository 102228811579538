import axios from 'axios'
import { parseJwt } from 'src/heplers'
import authHeader from './auth-header'

const API_URL = window._env_.REACT_APP_API_HOST

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + '/auth/login', {
        email,
        password
      })
      .then(response => {
        if (parseJwt(response.data.accessToken).role == 'USER') {
          return { error: 'Access Denied' }
        } else {
          if (response.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data))
          }
        }

        return response.data
      })
  }

  authLink(email) {
    return axios
      .post(API_URL + '/auth/admin/auth-link-request', {
        email
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
      })
  }

  logout() {
    return axios.post(API_URL + '/auth/logout', {}, { headers: authHeader() })
  }

  validateToken(token) {
    return axios
      .post(API_URL + '/auth/token-login', {
        token
      })
      .then(response => {
        if (parseJwt(response.data.accessToken).role == 'USER') {
          return { error: 'Access Denied' }
        } else {
          if (response.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data))
          }
        }

        return response.data
      })
  }

  // validateGoogle(code, domain) {
  //   return axios
  //     .post(API_URL + "/v1/google/callback", {
  //       code,
  //       domain
  //     })
  // }

  // validateOutlook(code, domain) {
  //   return axios
  //     .post(API_URL + "/v1/outlook/callback", {
  //       code,
  //       domain
  //     })
  // }

  authorizeGoogle(code, domain) {
    return axios.post(
      API_URL + '/v1/google/authorize',
      {
        code,
        domain,
        redirectUrl: window.location.hostname
      },
      { headers: authHeader() }
    )
  }

  authorizeOutlook(code) {
    return axios.post(
      API_URL + '/v1/outlook/authorize',
      {
        code,
        redirectUrl: window.location.hostname
      },
      { headers: authHeader() }
    )
  }

  authorizeSlack(code) {
    return axios.post(
      API_URL + '/v1/slack/authorize',
      {
        code,
        redirectUrl: window.location.hostname
      },
      { headers: authHeader() }
    )
  }

  authorizeOutlookWorkflows(code) {
    return axios.post(API_URL + '/v1/microsoft-alerts/token/' + code, {}, { headers: authHeader() })
  }

  authorizeGoogleSso(code) {
    return axios
      .post(API_URL + '/v1/google/sso/authorize', {
        code,
        redirect: 'ADMIN',
        redirectUrl: window.location.hostname
      })
      .then(response => {
        if (['COMPANY_ADMIN', 'ADMIN'].includes(parseJwt(response.data.accessToken).role)) {
          if (response.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data))
          }
        }

        return response.data
      })
  }

  authorizeOutlookSso(code) {
    return axios
      .post(API_URL + '/v1/outlook/sso/authorize', {
        code,
        redirect: 'ADMIN',
        redirectUrl: window.location.hostname
      })
      .then(response => {
        if (['COMPANY_ADMIN', 'ADMIN'].includes(parseJwt(response.data.accessToken).role)) {
          if (response.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data))
          }
        }

        return response.data
      })
  }

  authorizeOkta(code, email) {
    return axios
      .post(
        API_URL + '/v1/okta/sso/authorize',
        {
          code,
          email,
          redirect: 'ADMIN',
          redirectUrl: window.location.hostname
        }
        // { headers: authHeader() }
      )
      .then(response => {
        if (['COMPANY_ADMIN', 'ADMIN'].includes(parseJwt(response.data.accessToken).role)) {
          if (response.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data))
          }
        }
        return response.data
      })
  }

  authorizeOktaSso(code, domain) {
    return axios
      .post(API_URL + '/v1/okta/sso/authorize', {
        code,
        domain,
        redirect: 'ADMIN',
        redirectUrl: window.location.hostname
      })
      .then(response => {
        if (['COMPANY_ADMIN', 'ADMIN'].includes(parseJwt(response.data.accessToken).role)) {
          if (response.data.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data))
          }
        }
        return response.data
      })
  }

  authorizeAD(clientId, secretId, token) {
    return axios.post(
      API_URL + '/v1/ad/authorize',
      {
        clientId,
        clientSecret: secretId,
        tenantId: token
      },
      { headers: authHeader() }
    )
  }
  updateAD(clientId, secretId, token) {
    return axios.post(
      API_URL + '/v1/ad/update',
      {
        clientId,
        clientSecret: secretId,
        tenantId: token
      },
      { headers: authHeader() }
    )
  }

  healthAD(clientId, secretId, token) {
    return axios.post(
      API_URL + '/v1/ad/health',
      {
        clientId: clientId,
        clientSecret: secretId,
        tenantId: token
      },
      { headers: authHeader() }
    )
  }

  healthGoogleAlerts(googleClientId, mainContact) {
    return axios.post(
      API_URL + '/v1/google-alerts/authorization',
      {
        googleClientId,
        mainContact
      },
      { headers: authHeader() }
    )
  }
}

export default new AuthService()
